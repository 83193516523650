<div class="body-header">
  <h3 translate>verify-system.component.title</h3>
</div>
<div class="body-content">
  <div class="content">
    <p>
      <strong translate>app.instructions</strong>
    </p>
    <p translate>verify-system.component.instructions</p>
    <div class="control">
      <span translate>verify-system.component.supported-browsers</span>
      <a class="icon" href="https://www.google.com/chrome/" alt="Chrome" target="_blank">
        <i lvIcon="chrome"></i>
      </a>
      <a class="icon" href="https://www.mozilla.org/en-US/firefox/new/" alt="Firefox" target="_blank">
        <i lvIcon="firefox"></i>
      </a>
      <a class="icon" href="https://support.apple.com/downloads/safari" alt="Safari" target="_blank">
        <i lvIcon="safari"></i>
      </a>
      <a class="icon" href="http://www.opera.com/download" alt="Opera" target="_blank">
        <i lvIcon="opera"></i>
      </a>
      <a class="icon" href="https://www.microsoft.com/en-ca/windows/microsoft-edge" alt="Edge" target="_blank">
        <i lvIcon="edge"></i>
      </a>
    </div>
    <lv-system-check [audio]="session.liveAgent"></lv-system-check>
  </div>
</div>
<div class="body-footer">
  <button
    [disabled]="!systemCheck.everythingWorks()"
    class="button is-primary is-fullwidth next-button"
    (click)="done()"
  >
    <span translate>app.next</span>
    <span class="icon">
      <i lvIcon="long-arrow-right"></i>
    </span>
  </button>
</div>
