import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AccountFormComponent } from '@components/account-form/account-form.component';
import { Account, Accounts } from '@models/account';
import { ResourceCollection } from '@models/resource';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-account-index',
  templateUrl: './account-index.component.html',
  styleUrls: ['./account-index.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AccountIndexComponent implements OnInit {
  accounts: ResourceCollection<Account>;

  constructor(private accountService: Accounts, private overlayService: OverlayService) {}

  ngOnInit() {
    this.accounts = this.accountService.$search();
  }

  edit(account: Account) {
    this.overlayService.open(AccountFormComponent, { account: account });
  }

  newAccount() {
    this.overlayService.open(AccountFormComponent, { account: this.accounts.$build() });
  }
}
