<table #data>
  <thead>
    <tr>
      <th colspan="2"></th>
      <th colspan="2" class="verifications center" translate>user-list.component.offline-verifications</th>
      <th colspan="2" class="verifications center" translate>user-list.component.live-verifications</th>
      <th></th>
    </tr>
    <tr>
      <th>
        <input type="search" [(ngModel)]="userFilter.name" lvI18nPlaceholder="user.name">
      </th>
      <th>
        <input type="search" [(ngModel)]="userFilter.email" lvI18nPlaceholder="user.email">
      </th>
      <th class="center" translate>user-list.component.this-month</th>
      <th class="center" translate>user-list.component.last-month</th>
      <th class="center" translate>user-list.component.this-month</th>
      <th class="center" translate>user-list.component.last-month</th>
      <th class="center" translate>user-list.component.all-time</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users.$toArray() | filterBy:userFilter">
      <td>
        <a routerLink="/users/{{ user.id }}">{{ user.name }}</a>
      </td>
      <td>
        <a href="mailto:{{ user.email }}">{{ user.email }}</a>
      </td>
      <td class="center">{{ user.verifications.offline.thisMonth }}</td>
      <td class="center">{{ user.verifications.offline.lastMonth }}</td>
      <td class="center">{{ user.verifications.live.thisMonth }}</td>
      <td class="center">{{ user.verifications.live.lastMonth }}</td>
      <td class="center">{{ user.verifications.allTime }}</td>
    </tr>
  </tbody>
</table>
<div class="controls">
  <button class="button" (click)="export()" translate>app.xlsx</button>
</div>
