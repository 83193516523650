<thead>
  <tr>
    <th translate>session.learner</th>
    <th translate>session.website</th>
    <th translate>session.name</th>
    <th translate>session.wait</th>
    <th></th>
  </tr>
</thead>
<tbody>
  <tr *ngIf="sessions.length === 0" class="nothing-yet">
    <td colspan="5" translate>claims-list.component.nothing-yet</td>
  </tr>
  <lv-claims-list-item
    *ngFor="let session of sessions"
    ngForTrackBy="trackBy"
    [session]="session"
  ></lv-claims-list-item>
</tbody>
