import { Injectable } from '@angular/core';
import { TokenService } from '@services/token-service';

import { Anomaly } from './anomaly';
import { AttacheOptions } from './attache-options';
import { AttendancePhoto } from './attendance-photo';
import { Deserialize } from './decorators';
import { Event } from './event';
import { Identification } from './identification';
import { Learner } from './learner';
import { Photo } from './photo';
import { HasMany, HasOne, Resource, ResourceService } from './resource';
import { Tokbox } from './tokbox';
import { Verification } from './verification';

export class AutomatedAttendanceOptions {
  minimumTime: number;
  maximumAbsent: number;
  maximumAssistance: number;

  constructor(options) {
    Object.assign(this, options);
  }

  get anythingEnabled(): boolean {
    return this.minimumTimeEnabled || this.maximumAbsentEnabled || this.maximumAssistanceEnabled;
  }

  get minimumTimeEnabled(): boolean {
    return this.minimumTime >= 0;
  }

  get maximumAbsentEnabled(): boolean {
    return this.maximumAbsent >= 0;
  }

  get maximumAssistanceEnabled(): boolean {
    return this.maximumAssistance >= 0;
  }
}

export class ServiceOptions {
  @Deserialize((o) => new AutomatedAttendanceOptions(o))
  automatedAttendance?: AutomatedAttendanceOptions;

  constructor(options) {
    Object.assign(this, options);
  }
}

export type VerificationStatus = 'pending' | 'passed' | 'failed';

export class Session extends Resource {
  @Deserialize((o) => new ServiceOptions(o))
  serviceOptions: ServiceOptions;

  // These are used when starting a session so we have a nice place to jam this data.
  @Deserialize((o) => new AttacheOptions(o))
  attacheOptions: AttacheOptions;

  @Deserialize((t) => t && new Tokbox(t))
  tokbox: Tokbox;

  @Deserialize((v) => v && new Date(v))
  claimedAt: Date;

  @Deserialize((v) => v && new Date(v))
  finishedAt: Date;

  @Deserialize((v) => v && new Date(v))
  startedAt: Date;

  @HasOne(Learner)
  learner: Learner;

  @HasOne(Identification)
  activeIdentification: Identification;

  @HasMany(AttendancePhoto)
  attendancePhotos: AttendancePhoto[];

  @HasMany(Verification)
  verifications: Verification[];

  @HasMany(Anomaly)
  anomalies: Anomaly[];

  @HasMany(Event)
  events: Event[];

  name: string;
  url: string;
  appId: string;
  token: string;
  status: string;
  services: string[];
  liveAgent: boolean;
  biometrics: boolean;
  failed: boolean;
  passed: boolean;
  pending: boolean;
  running: boolean;
  claimedById: string;
  duration: number;

  idVerified: boolean;
  attendanceVerified: boolean;

  idVerificationStatus: VerificationStatus;
  attendanceVerificationStatus: VerificationStatus;

  requiresIdentification: boolean;

  facePhoto: Photo;
  idPhoto: Photo;

  get liveAgentConnected(): boolean {
    if (this.tokbox) {
      return this.tokbox.connected;
    }
    return false;
  }

  get automatedAttendance(): boolean {
    return this.services.includes('automated_attendance');
  }

  get domain(): string {
    if (this.$resolved) {
      const url = new URL(this.url);
      return url.hostname;
    }
    return '';
  }

  get unclaimed(): boolean {
    return !this.claimedAt;
  }

  get claimed(): boolean {
    return !this.unclaimed;
  }

  get finished(): boolean {
    return !!this.finishedAt;
  }

  get started(): boolean {
    return !!this.startedAt;
  }

  calcWaitTime(): number {
    if (this.$resolved) {
      return Date.now() - this.createdAt.getTime();
    }
    return 0;
  }

  canPass(): boolean {
    if (this.failed || this.passed) {
      return false;
    }

    if (this.idVerified) {
      // If the ID is already verified, the session must be finished to pass attendance photos.
      // Negate because this is setting whether the button is disabled.
      return this.finished;
    }

    // If we have to verify ID, the session must be started.
    return this.started;
  }
}

@Injectable({ providedIn: 'root' })
export class Sessions extends ResourceService<Session> {
  constructor(tokenService: TokenService) {
    super(tokenService);
    this.base = 'sessions';
    this.ctor = Session;
  }

  claim(session: Session): Promise<Session> {
    return this.$post(session, 'claim');
  }

  start(session: Session): Promise<Session> {
    const learner = session.learner;
    const updates = {
      session: {
        learner_attributes: {
          id: learner.id,
          name: learner.name
        }
      }
    };
    if (session.requiresIdentification) {
      updates.session['identification_attributes'] = {
        learner_id: learner.id,
        face_photo: JSON.stringify(session.facePhoto.payload),
        id_photo: JSON.stringify(session.idPhoto.payload)
      };
    }
    return this.$post(session, 'start', updates);
  }
}
