import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SystemCheck } from '@services/system-check';

@Component({
  selector: 'lv-system-check',
  templateUrl: 'system-check.component.html',
  styleUrls: ['system-check.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SystemCheckComponent implements OnInit, OnDestroy {
  @Input() audio = false;

  webcamOk = false;
  websocketsOk = true;

  get browserName(): string {
    return this.systemCheck.browserName;
  }

  get microphoneOk(): boolean {
    return this.systemCheck.microphone;
  }

  get speakersOk(): boolean {
    return this.systemCheck.speakers;
  }

  get webrtcOk(): boolean {
    return this.systemCheck.webrtc;
  }

  get browserOk(): boolean {
    return this.systemCheck.browser;
  }

  get audioOk(): boolean {
    return this.microphoneOk && this.speakersOk;
  }

  get videoOk(): boolean {
    return this.webcamOk && this.webrtcOk;
  }

  get browserVersion() {
    return this.systemCheck.browserVersion;
  }

  constructor(private systemCheck: SystemCheck) {}

  ngOnInit() {
    this.doSystemCheck();
  }

  ngOnDestroy() {
    this.systemCheck.stop();
  }

  doSystemCheck() {
    this.systemCheck.start().then(() => {
      this.systemCheck.testWebsockets().then((ok) => (this.websocketsOk = ok));
      this.systemCheck.testWebcam().then((ok) => (this.webcamOk = ok));
    });
  }

  everythingWorks(): boolean {
    if (this.audio) {
      return this.websocketsOk && this.audioOk && this.videoOk;
    }
    return this.websocketsOk && this.videoOk;
  }

  browserCompatibility(): boolean {
    return this.browserOk && this.websocketsOk && this.webrtcOk;
  }

  onRetry() {
    this.doSystemCheck();
  }
}
