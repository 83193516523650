import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceCollection } from '@models/resource';
import { Session, Sessions } from '@models/session';
import { User, Users } from '@models/user';
import { Verification } from '@models/verification';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lv-user-details',
  templateUrl: 'user-details.component.html',
  styleUrls: ['user-details.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit {
  userId: number;
  user: User;
  sessions: ResourceCollection<Session>;

  @HostBinding('class.is-loading') get isLoading(): boolean {
    return !this.sessions.$resolved;
  }

  constructor(
    private userService: Users,
    private sessionService: Sessions,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.id;
      this.user = this.userService.$find(this.userId);
      this.sessions = this.sessionService.$search({ for_user: this.userId });
    });
  }

  services(session: Session): string {
    return session.services.map((service) => this.translate.instant(`session._service.${service}`)).join(', ');
  }

  servicesVerified(session: Session): string {
    return this.verificationsForUse(session)
      .map((verification) => this.translate.instant(`verification._service.${verification.service}`))
      .join(', ');
  }

  verifiedAt(session: Session): Date {
    return this.verificationsForUse(session).map((v) => v.createdAt)[0];
  }

  visit(page: number) {
    this.sessions.$refresh({ for_user: this.userId, page: page });
  }

  paginationConfig() {
    if (this.sessions && this.sessions.pagination) {
      return {
        itemsPerPage: this.sessions.pagination.perPage,
        currentPage: this.sessions.pagination.currentPage,
        totalItems: this.sessions.pagination.totalItems
      };
    }

    // Just return some defaults
    return {
      itemsPerPage: 25,
      currentPage: 1,
      totalItems: 0
    };
  }

  private verificationsForUse(session: Session): Verification[] {
    return session.verifications.filter((verification) => verification.userId === parseInt(this.user.id, 10));
  }
}
