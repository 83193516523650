<section lvCloseOverlay withKeyboard="esc">
  <h4>{{ title | translate }}</h4>
  <form novalidate (ngSubmit)="save()" #form="ngForm">
    <div class="field">
      <input type="text" name="url" [(ngModel)]="webhook.url" placeholder="URL" required class="input control">
    </div>
    <div class="field is-grouped is-grouped-right">
      <input type="submit" lvI18nValue="app.save" [disabled]="!form.valid" class="button is-success control">
      <button lvCloseOverlay class="button control" translate>app.cancel</button>
    </div>
  </form>
</section>
