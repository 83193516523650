import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

import { TokenService } from './token-service';

@Injectable({ providedIn: 'root' })
export class RequireAuthenticatedUser implements CanActivate, CanActivateChild {
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(): Promise<boolean> {
    return this.tokenService.canActivate().then((ok) => {
      if (!ok) {
        this.tokenService.setRedirectTo(window.location.pathname + window.location.search);
        this.router.navigateByUrl('auth/sign_in');
      }
      return ok;
    });
  }

  canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
