import { Identification } from './identification';
import { HasMany, Resource } from './resource';

export class Learner extends Resource {
  @HasMany(Identification)
  identification: Identification;

  name: string;
  email?: string;
  appId: string;
  biometricsTrained: boolean;
}
