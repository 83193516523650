import { Injectable } from '@angular/core';
import { TokenService } from '@services/token-service';

import { Resource, ResourceService } from './resource';

export class Webhook extends Resource {
  url: string;
  event: string;
  accountId: number;
  signingToken: string;
}

@Injectable({ providedIn: 'root' })
export class Webhooks extends ResourceService<Webhook> {
  constructor(tokenService: TokenService) {
    super(tokenService);
    this.base = 'webhooks';
    this.ctor = Webhook;
  }
}
