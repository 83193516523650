import { Injectable } from '@angular/core';
import { TokenService } from '@services/token-service';

import { HasMany, Resource, ResourceCollection, ResourceService } from './resource';
import { Webhook } from './webhook';

export class Account extends Resource {
  @HasMany(Webhook, true) webhooks: ResourceCollection<Webhook>;

  name: string;
  token: string;
}

@Injectable({ providedIn: 'root' })
export class Accounts extends ResourceService<Account> {
  constructor(tokenService: TokenService) {
    super(tokenService);
    this.base = 'accounts';
    this.ctor = Account;
  }
}
