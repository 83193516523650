import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Notifier {
  notificationIcon = require('../../assets/notification.png');
  notes: { [k: string]: number } = require('./notes.json');
  audioContext: AudioContext;

  constructor() {
    const audioContext = window['AudioContext'] || window['webkitAudioContext'];
    if (audioContext) {
      this.audioContext = new audioContext();
    }
  }

  get supported(): boolean {
    return 'Notification' in window;
  }

  get hasPermission(): boolean {
    return (<any>Notification).permission === 'granted';
  }

  get ok(): boolean {
    return this.supported && this.hasPermission;
  }

  requestPermission() {
    if (this.supported && !this.hasPermission) {
      Notification.requestPermission();
    }
  }

  notify(message: string): Notification | null {
    this.ding();
    return this.push(message);
  }

  // http://marcgg.com/blog/2016/11/01/javascript-audio/
  private ding() {
    if (this.audioContext) {
      const oscillator = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      oscillator.type = 'sine';
      oscillator.connect(gain);
      oscillator.frequency.value = this.notes['A4'];
      gain.connect(this.audioContext.destination);
      oscillator.start(0);
      gain.gain.exponentialRampToValueAtTime(0.00001, this.audioContext.currentTime + 1);
    }
  }

  private push(message: string): Notification | null {
    if (!this.ok) {
      return;
    }

    return new Notification(message, {
      icon: this.notificationIcon
    });
  }
}
