import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Account, Accounts } from '@models/account';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AccountFormComponent {
  @Input() account: Account;

  constructor(private accountService: Accounts, private overlayService: OverlayService) {}

  save() {
    this.accountService.$save(this.account).then(
      () => this.overlayService.closeTopOverlay(),
      (account: Account) => console.error(account.$error)
    );
  }

  get title(): string {
    return `account-form.component.title.${this.account.newRecord()}`;
  }
}
