import { Directive, HostListener, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { OverlayService } from '@services/overlay';
import * as mousetrap from 'mousetrap';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'button[lvCloseOverlay], a[lvCloseOverlay]'
})
export class CloseOverlayClickDirective {
  constructor(private overlayService: OverlayService) {}

  @HostListener('click', ['$event']) close(event: Event) {
    event.preventDefault();
    this.overlayService.closeTopOverlay();
  }
}

@Directive({
  selector: '[lvCloseOverlay][withKeyboard]'
})
export class CloseOverlayKeyboardDirective implements OnInit, OnDestroy {
  @Input() withKeyboard: string;

  constructor(private overlayService: OverlayService, private zone: NgZone) {}

  ngOnInit() {
    mousetrap.bind(this.withKeyboard, () => {
      this.zone.run(() => {
        this.overlayService.closeTopOverlay();
      });
    });
  }

  ngOnDestroy() {
    mousetrap.unbind(this.withKeyboard);
  }
}

@Directive({
  selector: '[lvCloseOverlay][after]'
})
export class CloseOverlayAfterDirective implements OnInit, OnDestroy {
  @Input() after: string;
  timeoutId: number;

  constructor(private overlayService: OverlayService, private zone: NgZone) {}

  get timeout(): number {
    let time = 0;
    const match = this.after.match(/(\d+)s/);
    if (match) {
      time = parseInt(match[1], 10) * 1000;
    } else {
      time = parseInt(this.after, 10);
    }
    return time;
  }

  ngOnInit() {
    this.timeoutId = window.setTimeout(() => {
      this.overlayService.closeTopOverlay();
      this.timeoutId = null;
    }, this.timeout);
  }

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}
