import {
  Component,
  ComponentFactoryResolver,
  HostBinding,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-overlays',
  templateUrl: './overlays.component.html',
  styleUrls: ['./overlays.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class OverlaysComponent implements OnInit {
  @ViewChild('overlayContainer', { static: true, read: ViewContainerRef }) overlayContainer: ViewContainerRef;

  constructor(private overlayService: OverlayService, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.overlayService.overlaysChanged.asObservable().subscribe((overlay) => this.openOverlay(overlay));
  }

  @HostBinding('hidden') get hidden(): boolean {
    return !this.anyOverlays();
  }

  anyOverlays(): boolean {
    return this.overlayService.overlays.length > 0;
  }

  openOverlay(overlay: Overlay) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(overlay.component);
    const ref = this.overlayContainer.createComponent(factory);
    const instance = ref.instance;
    for (const key in overlay.input) {
      instance[key] = overlay.input[key];
    }
    ref.changeDetectorRef.detectChanges();
    overlay.close.asObservable().subscribe(() => ref.destroy());
  }
}
