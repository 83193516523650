<section lvCloseOverlay withKeyboard="esc">
  <h4>{{ title | translate }}</h4>
  <div class="form">
    <iframe [src]="safeURL" frameborder="0"></iframe>
    <button class="button is-primary is-fullwidth" lvCloseOverlay>
      <span class="icon">
        <i lvIcon="times"></i>
      </span>
      <span translate>app.close</span>
    </button>
  </div>
</section>
