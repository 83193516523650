<div class="body-header">
  <h3 translate>verify-instructions.component.title</h3>
</div>
<div class="body-content">
  <div class="text-column content">
    <h5>
      <strong translate>verify-instructions.component.intro</strong>
    </h5>
    <p translate>verify-instructions.component.simple</p>
    <form #learnerForm="ngForm">
      <div class="checkbox control" *ngIf="session.requiresIdentification">
        <input id="hasID" name="hasID" type="checkbox" [(ngModel)]="hasID" required />
        <label for="hasID">
          <strong translate>verify-instructions.component.require-id</strong>
        </label>
      </div>
      <div class="checkbox control">
        <input id="hasPP" name="hasPP" type="checkbox" [(ngModel)]="hasPP" required />
        <label for="hasPP">
          <strong
            >{{ 'verify-instructions.component.agree-to' | translate }}
            <a (click)="showPolicy($event)" translate>verify-instructions.component.privacy-policy</a>
            <span> &amp; </span>
            <a (click)="showTerms($event)" translate>verify-instructions.component.terms-use</a>
          </strong>
        </label>
      </div>
      <p translate>verify-instructions.component.ready</p>
    </form>
  </div>
  <div class="image-column">
    <img src="assets/lv-learner.svg" alt="LV Learner" />
  </div>
</div>
<div class="body-footer">
  <button class="button is-primary is-fullwidth next-button" (click)="done()" [disabled]="learnerForm.invalid">
    <span translate>app.next</span>
    <span class="icon">
      <i lvIcon="long-arrow-right"></i>
    </span>
  </button>
</div>
