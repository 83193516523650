<h3 translate>walk-step-complete.component.title</h3>
<p translate>walk-step-complete.component.info.intro</p>
<lv-script-toggle>
  <p translate>walk-step-complete.component.script.intro</p>
  <p translate>walk-step-complete.component.script.body</p>
  <p translate>walk-step-complete.component.script.outro</p>
  <p translate>walk-step-complete.component.script.sign-off</p>
</lv-script-toggle>
<lv-automated-attendance-options *ngIf="displayAutomatedAttendanceOptions" [options]="session.serviceOptions.automatedAttendance"></lv-automated-attendance-options>
<p translate>walk-step-complete.component.info.outro</p>
