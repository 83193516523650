import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-biometrics-error',
  styleUrls: ['biometrics-error.component.sass'],
  templateUrl: 'biometrics-error.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BiometricsErrorComponent {
  allowHardRestart = false;
  restart: EventEmitter<any>;
  reset: EventEmitter<any>;

  constructor(private overlayService: OverlayService) {}

  resetBiometrics() {
    this.reset.emit();
    this.overlayService.closeTopOverlay();
  }

  hardRestart() {
    this.restart.emit();
    this.overlayService.closeTopOverlay();
  }
}
