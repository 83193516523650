import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Session, Sessions } from '@models/session';
import { TranslateService } from '@ngx-translate/core';
import * as ActionCable from '@rails/actioncable';
import { Cable } from '@services/cable';

interface SessionChannelParams {
  finished?: boolean;
}

@Component({
  selector: 'lv-claims-list-item',
  templateUrl: './claims-list-item.component.html',
  styleUrls: ['claims-list-item.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimsListItemComponent implements OnInit, OnDestroy {
  @Input() session: Session;
  waitTime: number;
  shuttingDown: boolean;

  channel: ActionCable.Channel;

  get learner() {
    return this.session.learner;
  }

  constructor(
    private sessionService: Sessions,
    private router: Router,
    private translate: TranslateService,
    private cable: Cable
  ) {}

  @HostBinding('class.is-claimed') get isClaimed(): boolean {
    return this.session.claimed;
  }

  @HostBinding('class.is-live') get isLive(): boolean {
    return this.session.services.includes('live_id_verification');
  }

  @HostBinding('class.is-finished') get isFinished(): boolean {
    return this.session.finished;
  }

  ngOnInit() {
    this.shuttingDown = false;

    if (this.session.claimed && !this.session.finished) {
      const cableParams = {
        channel: 'SessionChannel',
        token: this.session.token
      };
      this.channel = this.cable.create(cableParams, (data: SessionChannelParams) => {
        if (data.finished) {
          this.session.$refresh();
        }
      });
    }

    this.updateWaitTime();
  }

  ngOnDestroy() {
    this.shuttingDown = true;

    if (this.channel) {
      this.channel.unsubscribe();
    }
  }

  updateWaitTime() {
    if (this.shuttingDown) {
      return;
    }

    requestAnimationFrame(() => this.updateWaitTime());
    this.waitTime = this.session.calcWaitTime();
  }

  claim() {
    this.sessionService.claim(this.session);
  }

  services() {
    return this.session.services.map((service) => this.translate.instant(`session._service.${service}`)).join(', ');
  }
}
