<section lvCloseOverlay withKeyboard="esc">
  <h4 translate>dismiss-confirm.component.title</h4>
  <div class="content">
    <p translate>dismiss-confirm.component.message</p>
    <div class="field is-grouped is-grouped-right">
      <button class="control button" lvCloseOverlay translate>app.cancel</button>
      <button class="control button is-primary" (click)="exit()" translate>app.ok</button>
    </div>
  </div>
</section>
