import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DoneAuditingComponent } from '@components/done-auditing/done-auditing.component';
import { Identification } from '@models/identification';
import { Session } from '@models/session';
import { Verifications } from '@models/verification';
import { AttachePayload } from '@services/attache';
import { Cable } from '@services/cable';
import { OverlayService } from '@services/overlay';
import * as ActionCable from '@rails/actioncable';
import { Subscription } from 'rxjs';

interface SessionChannelData {
  freshFacePhoto?: AttachePayload;
  freshIdPhoto?: AttachePayload;
}

@Component({
  selector: 'lv-agent-verify-id',
  templateUrl: './agent-verify-id.component.html',
  styleUrls: ['./agent-verify-id.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AgentVerifyIdComponent implements OnInit, OnDestroy {
  @Input() session: Session;
  @Input() passed: EventEmitter<boolean>;
  @Input() failed: EventEmitter<string>;

  channel: ActionCable.Channel;

  freshFacePhoto: AttachePayload;
  freshIdPhoto: AttachePayload;

  passedSubscription: Subscription;
  failedSubscription: Subscription;

  constructor(
    private verificationService: Verifications,
    private overlayService: OverlayService,
    private router: Router,
    private cable: Cable
  ) {}

  get identification(): Identification {
    return this.session.activeIdentification;
  }

  get facePhotoUrl(): string | null {
    if (this.freshFacePhoto) {
      return this.session.attacheOptions.urlFor(this.freshFacePhoto);
    }

    if (this.identification) {
      return this.identification.facePhotoUrl;
    }

    return null;
  }

  get idPhotoUrl(): string | null {
    if (this.freshIdPhoto) {
      return this.session.attacheOptions.urlFor(this.freshIdPhoto);
    }

    if (this.identification) {
      return this.identification.idPhotoUrl;
    }

    return null;
  }

  ngOnInit() {
    this.passedSubscription = this.passed.asObservable().subscribe(() => this.passSession());

    this.failedSubscription = this.failed.asObservable().subscribe((reason) => this.failSession(reason));
    const cableParams = {
      channel: 'SessionChannel',
      token: this.session.token
    };
    this.channel = this.cable.create(cableParams, (data: SessionChannelData) => {
      if (data.freshFacePhoto) {
        this.freshFacePhoto = data.freshFacePhoto;
      }

      if (data.freshIdPhoto) {
        this.freshIdPhoto = data.freshIdPhoto;
      }
    });

    this.channel.perform('rebroadcast', { requestPhotos: true });
  }

  ngOnDestroy() {
    this.passedSubscription.unsubscribe();
    this.failedSubscription.unsubscribe();
    this.channel.unsubscribe();
  }

  passSession() {
    this.verificationService.pass(this.session, 'identification').then(() => {
      if (!this.session.finished || this.session.automatedAttendance) {
        this.goHome();
      }
    });
  }

  failSession(reason: string) {
    this.verificationService.fail(this.session, 'identification', reason).then(() => {
      this.overlayService.open(DoneAuditingComponent, {
        session: this.session,
        passOrFail: 'fail'
      });
      this.goHome();
    });
  }

  goHome() {
    this.router.navigateByUrl('/');
  }

  retakePhoto() {
    this.channel.perform('rebroadcast', { currentStep: 3, nextStep: 6 });
  }

  retakeID() {
    this.channel.perform('rebroadcast', { currentStep: 4, nextStep: 6 });
  }
}
