export class LocalStorageProxy {
  constructor(public prefix: string) {}

  get(key: string): any | null {
    return localStorage.getItem(this.buildKey(key));
  }

  getJSON(key: string): any | null {
    const data = this.get(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  set(key: string, value: string) {
    localStorage.setItem(this.buildKey(key), value);
  }

  setJSON(key: string, value: any) {
    localStorage.setItem(this.buildKey(key), JSON.stringify(value));
  }

  remove(key: string) {
    localStorage.removeItem(this.buildKey(key));
  }

  private buildKey(key: string): string {
    return `${this.prefix}-${key}`;
  }
}
