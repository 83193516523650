import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { Session } from '@models/session';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@services/overlay';

@Directive({ selector: '[lvReasonCheck]' })
export class ReasonCheckboxDirective {
  @Input() index: number;
  checked: boolean;

  @HostListener('change') toggle() {
    this.checked = !this.checked;
  }
}

@Component({
  selector: 'lv-session-failed-reason',
  templateUrl: 'session-failed-reason.component.html',
  styleUrls: ['session-failed-reason.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SessionFailedReasonComponent implements OnInit {
  @ViewChildren(ReasonCheckboxDirective) reasons: QueryList<ReasonCheckboxDirective>;

  @Input() session: Session;
  @Input() submitted: EventEmitter<string>;

  reason = '';

  cannedReasons: Array<string>;

  constructor(private overlayService: OverlayService, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('session-failed-reason.component.canned-reasons').subscribe((res) => (this.cannedReasons = res));
  }

  updateReasons() {
    this.reason = this.reasons.reduce(
      (comment, reason) => comment + (reason.checked ? `${this.cannedReasons[reason.index]}\n` : ''),
      ''
    );
  }

  submit() {
    this.submitted.emit(this.reason);
    this.overlayService.closeTopOverlay();
  }
}
