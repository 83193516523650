import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lv-captioned-image',
  templateUrl: 'captioned-image.component.html',
  styleUrls: ['captioned-image.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CaptionedImageComponent {
  @Input() src: string;
  @Input() caption: string;
  @Input() allowRetake: boolean;
  @Output() retake = new EventEmitter();
}
