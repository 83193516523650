<p>
  <strong translate>automated-attendance-options.component.intro</strong>
</p>
<p translate>automated-attendance-options.component.description</p>
<ul>
  <li *ngIf="options.minimumTimeEnabled" [innerHTML]="'automated-attendance-options.component.minimum-time' | translate:{ value: options.minimumTime }"
    [attr.data-tip]="'automated-attendance-options.component.minimum-time-tip' | translate" data-tip-length="large"></li>
  <li *ngIf="options.maximumAbsentEnabled" [innerHTML]="'automated-attendance-options.component.maximum-absent' | translate:{ value: options.maximumAbsent }"
    [attr.data-tip]="'automated-attendance-options.component.maximum-absent-tip' | translate" data-tip-length="large"></li>
  <li *ngIf="options.maximumAssistanceEnabled" [innerHTML]="'automated-attendance-options.component.maximum-assistance' | translate:{ value: options.maximumAssistance }"
    [attr.data-tip]="'automated-attendance-options.component.maximum-assistance-tip' | translate" data-tip-length="large"></li>
</ul>
