import {
  Component,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Anomaly } from '@models/anomaly';
import { AttendancePhoto } from '@models/attendance-photo';
import { Identification } from '@models/identification';
import { PhotoComment } from '@models/photo-comment';
import { ResourceCollection } from '@models/resource';
import { Session } from '@models/session';
import { EventService } from '@services/event-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[lvImgBuffer]'
})
export class ImgBufferDirective implements OnInit, OnDestroy {
  observer: MutationObserver;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('load') onLoad() {
    setTimeout(() => this.renderer.removeClass(this.nativeEl, 'is-loading'));
  }

  get nativeEl(): HTMLImageElement {
    return this.el.nativeElement;
  }

  ngOnInit() {
    this.observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
          this.renderer.addClass(this.nativeEl, 'is-loading');
        }
      }
    });

    this.observer.observe(this.nativeEl, { attributes: true });
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}

@Component({
  selector: 'lv-session-review-attendance',
  templateUrl: 'session-review-attendance.component.html',
  styleUrls: ['session-review-attendance.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SessionReviewAttendanceComponent implements OnInit {
  activeSession: Session;

  selectedCommentIndex = 0;
  selectedPhotoIndex = 0;

  selectedAnomaly: Anomaly;
  selectedAnomalyIndex = 0;

  sessionDurationSum: number;

  absenceCount = 0;
  assistanceCount = 0;

  @Input() set sessions(sessions: ResourceCollection<Session>) {
    this._sessions = sessions;

    this.sessionDurationSum = this.sumSessionDurations(this._sessions);

    const anomalyCount = this.countAnomalies(this._sessions);
    this.absenceCount = anomalyCount.absent;
    this.assistanceCount = anomalyCount.assistance;

    if (!this.activeSession) {
      this.activeSession = sessions.$at(0);
    }

    setTimeout(() => this.events.publish('anomaly:select', this.activeSession.anomalies[0]));
  }

  get sessions(): ResourceCollection<Session> {
    return this._sessions;
  }

  private ngUnsubscribe: Subject<EventService> = new Subject();
  private _sessions: ResourceCollection<Session>;

  constructor(private events: EventService) {}

  ngOnInit() {
    this.events
      .of('anomaly:select')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((anomaly: Anomaly) => {
        if (anomaly !== this.selectedAnomaly) {
          this.selectedAnomaly = anomaly;
          this.selectPhotoFromAnomaly(anomaly);
          this.selectedAnomalyIndex = this.anomalies.indexOf(anomaly);
        }
      });
  }

  sumSessionDurations(sessions: ResourceCollection<Session>): number {
    return sessions
      .$toArray()
      .map((session) => Math.round(session.duration / 60))
      .reduce((a, b) => a + b, 0);
  }

  countAnomalies(sessions: ResourceCollection<Session>) {
    const anomalyCount = { assistance: 0, absent: 0 };

    for (const session of sessions) {
      if (!this.activeSession && session.anomalies.length) {
        this.activeSession = session;
      }

      const assists = session.anomalies.filter((anomaly) => anomaly.kind === 'assistance');
      const absents = session.anomalies.filter((anomaly) => anomaly.kind === 'absent');

      anomalyCount.assistance += assists.length;
      anomalyCount.absent += absents.length;
    }

    return anomalyCount;
  }

  // Sessions
  get identification(): Identification {
    return this.activeSession.activeIdentification;
  }

  getSession(id: number): Session {
    return this.sessions.$findById(id);
  }

  // Photo
  get selectedPhoto(): AttendancePhoto {
    return this.flaggedPhotos[this.selectedPhotoIndex];
  }

  get photoNumber(): number {
    return this.selectedPhotoIndex + 1;
  }

  get photoCount(): number {
    return this.flaggedPhotos.length;
  }

  get hasFlaggedPhotos(): boolean {
    return this.photoCount > 0;
  }

  get flaggedPhotos(): AttendancePhoto[] {
    let allFlaggedPhotos: AttendancePhoto[] = [];
    this.sessions.$toArray().map((session) => {
      allFlaggedPhotos = allFlaggedPhotos.concat(session.attendancePhotos.filter((photo) => photo.flagged));
    });

    return allFlaggedPhotos;
  }

  isFirstPhoto(): boolean {
    return this.selectedPhotoIndex === 0;
  }

  isLastPhoto(): boolean {
    return this.selectedPhotoIndex === this.photoCount - 1;
  }

  nextPhoto() {
    this.selectedPhotoIndex = Math.min(this.selectedPhotoIndex + 1, this.photoCount);

    if (this.selectedPhoto.anomalyId !== this.selectedAnomaly.id) {
      this.selectAnomalyFromPhoto(this.selectedPhoto);
    }
  }

  previousPhoto() {
    this.selectedPhotoIndex = Math.max(this.selectedPhotoIndex - 1, 0);

    if (this.selectedPhoto.anomalyId !== this.selectedAnomaly.id) {
      this.selectAnomalyFromPhoto(this.selectedPhoto);
    }
  }

  photoCreatedAt(): Date {
    return this.selectedPhoto.createdAt;
  }

  selectPhotoFromAnomaly(anomaly: Anomaly) {
    if (anomaly === undefined) {
      return;
    }

    const newPhoto: AttendancePhoto = this.flaggedPhotos.find((photo) => anomaly.id === photo.anomalyId);

    if (newPhoto !== this.selectedPhoto) {
      this.selectedPhotoIndex = this.flaggedPhotos.indexOf(newPhoto);
    }
  }

  // Photo Comments
  get photoComments(): PhotoComment[] {
    if (this.selectedPhoto) {
      return this.selectedPhoto.photoComments;
    }
    return [];
  }

  get selectedComment(): string {
    const photoComment = this.photoComments[this.selectedCommentIndex];
    if (photoComment) {
      return photoComment.comment;
    }
    return null;
  }

  get commentCount(): number {
    return this.photoComments.length;
  }

  isFirstComment(): boolean {
    return this.selectedCommentIndex === 0;
  }

  isLastComment(): boolean {
    return this.selectedCommentIndex === this.commentCount - 1;
  }

  nextComment() {
    this.selectedCommentIndex = Math.min(this.selectedCommentIndex + 1, this.commentCount);
  }

  previousComment() {
    this.selectedCommentIndex = Math.max(this.selectedCommentIndex - 1, 0);
  }

  // Attendance
  get serviceOptions() {
    return this.activeSession.serviceOptions;
  }

  get automatedAttendance(): any {
    return this.serviceOptions.automatedAttendance;
  }

  get anythingEnabled(): boolean {
    return this.automatedAttendance.anythingEnabled;
  }

  get maximumAbsentEnabled(): boolean {
    return this.automatedAttendance.maximumAbsentEnabled;
  }

  get maximumAbsent(): number {
    return this.automatedAttendance.maximumAbsent;
  }

  get minimumTimeEnabled(): boolean {
    return this.automatedAttendance.minimumTimeEnabled;
  }

  get minimumTime(): number {
    return this.automatedAttendance.minimumTime;
  }

  get maximumAssistanceEnabled(): boolean {
    return this.automatedAttendance.maximumAssistanceEnabled;
  }

  get maximumAssistance(): number {
    return this.automatedAttendance.maximumAssistance;
  }

  get totalSessionDuration(): number {
    return this.sessionDurationSum;
  }

  get attendanceVerificationStatus(): string {
    if (this._sessions.filter((session) => session.attendanceVerificationStatus === 'failed').length) {
      return 'failed';
    }
    return 'passed';
  }

  // Anomalies
  selectAnomalyFromPhoto(photo: AttendancePhoto) {
    if (photo === undefined) {
      return;
    }

    const sessionId: number = photo.sessionId;
    const newAnomaly: Anomaly = this.getSession(sessionId).anomalies.find((anomaly) => anomaly.id === photo.anomalyId);

    if (newAnomaly !== this.selectedAnomaly) {
      this.selectedAnomaly = newAnomaly;
      this.events.publish('anomaly:select', this.selectedAnomaly);

      this.selectedAnomalyIndex = this.anomalies.indexOf(newAnomaly);
    }
  }

  get anomalies(): Anomaly[] {
    let allAnomalies: Anomaly[] = [];

    this.sessions.$toArray().forEach((session) => {
      allAnomalies = allAnomalies.concat(session.anomalies);
    });

    return allAnomalies;
  }

  get anomalyCount(): number {
    return this.anomalies ? this.anomalies.length : 0;
  }

  isFirstAnomaly(): boolean {
    return this.selectedAnomalyIndex === 0;
  }

  isLastAnomaly(): boolean {
    return this.selectedAnomalyIndex === this.anomalyCount - 1;
  }

  nextAnomaly() {
    this.selectedAnomalyIndex = Math.min(this.selectedAnomalyIndex + 1, this.anomalyCount);

    this.selectedAnomaly = this.anomalies[this.selectedAnomalyIndex];
    this.events.publish('anomaly:select', this.selectedAnomaly);

    this.selectPhotoFromAnomaly(this.selectedAnomaly);
  }

  previousAnomaly() {
    this.selectedAnomalyIndex = Math.max(this.selectedAnomalyIndex - 1, 0);

    this.selectedAnomaly = this.anomalies[this.selectedAnomalyIndex];
    this.events.publish('anomaly:select', this.selectedAnomaly);

    this.selectPhotoFromAnomaly(this.selectedAnomaly);
  }
}
