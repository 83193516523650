<section lvCloseOverlay withKeyboard="esc">
  <h4 translate>flag-photo.component.title</h4>
  <div class="form">
    <div class="field">
      <textarea [(ngModel)]="comment" cols="30" rows="10" resize="false" class="textarea" autofocus></textarea>
    </div>
    <div class="field is-grouped is-grouped-right">
      <button class="button is-primary control" (click)="submit()" [disabled]="!comment" translate>flag-photo.component.flag</button>
      <button lvCloseOverlay class="button control" translate>app.cancel</button>
    </div>
  </div>
</section>
