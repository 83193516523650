<div class="body-header">
  <h3 translate>verify-ready.component.title</h3>
</div>
<div class="body-content">
  <div class="content">
    <p>
      <strong translate>verify-ready.component.{{ agentOrNone }}.intro</strong>
    </p>
    <lv-automated-attendance-options
      *ngIf="displayAutomatedAttendanceOptions"
      [options]="session.serviceOptions.automatedAttendance"
    ></lv-automated-attendance-options>
    <p [innerHtml]="'verify-ready.component.behaviors' | translate"></p>
    <ul class="behaviour-list">
      <li class="behaviour is-dont">
        <span>
          <img src="assets/dont-coverface.svg" />
        </span>
      </li>
      <li class="behaviour is-dont">
        <span>
          <img src="assets/dont-lookaway.svg" />
        </span>
      </li>
      <li class="behaviour is-do">
        <span>
          <img src="assets/do-facecamera.svg" />
        </span>
      </li>
    </ul>
  </div>
</div>
<div class="body-footer">
  <button *ngIf="!session.liveAgent" class="button is-primary is-fullwidth next-button" (click)="done()">
    <span translate>app.start</span>
    <span class="icon">
      <i lvIcon="long-arrow-right"></i>
    </span>
  </button>
</div>
