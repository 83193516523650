import { EventEmitter, Injectable } from '@angular/core';

export class Overlay {
  component;
  input: Object;
  close: EventEmitter<boolean>;

  constructor(component, input) {
    this.component = component;
    this.input = input;
    this.close = new EventEmitter<boolean>();
  }
}

@Injectable({ providedIn: 'root' })
export class OverlayService {
  overlays: Overlay[] = [];
  overlaysChanged = new EventEmitter<Overlay>();

  constructor() {}

  open(component, input: Object): Overlay {
    const overlay = new Overlay(component, input);
    this.overlays.push(overlay);
    this.overlaysChanged.emit(overlay);

    return overlay;
  }

  closeTopOverlay() {
    const overlay = this.overlays.pop();
    if (overlay) {
      overlay.close.emit(this.overlays.length === 0);
    }
  }
}
