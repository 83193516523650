import { FaceDetection } from 'face-api.js';

export abstract class FaceTrackingData {
  faces: any[];

  json(): any {
    return { type: this.type, faces: JSON.stringify(this.faces) };
  }

  abstract get type(); // Corresponds to a rails STI model that extends FaceTrackingData
}

export class NativeFaceTrackingData extends FaceTrackingData {
  constructor(public faces: DetectedFace[]) {
    super();
  }

  get type(): string {
    return 'NativeDatum';
  }
}

export class FaceApiTrackingData extends FaceTrackingData {
  constructor(public faces: FaceDetection[]) {
    super();
  }

  get type(): string {
    return 'FaceApiDatum';
  }
}
