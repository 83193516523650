import { Injectable } from '@angular/core';
import { FaceApiTrackingData } from '@models/face-tracking-data';
import { TinyFaceDetector, TinyFaceDetectorOptions } from 'face-api.js';

import { FaceTracker } from './face-tracker';

@Injectable({ providedIn: 'root' })
export class FaceApiTrackerService extends FaceTracker {
  faceDetector: TinyFaceDetector;

  initialize(): Promise<this> {
    this.faceDetector = new TinyFaceDetector();
    return this.faceDetector.load('/assets/face-api').then(() => this);
  }

  protected trackFaces() {
    this.stream.camera
      .snapCanvas(this.stream)
      .then((canvas) => this.faceDetector.locateFaces(canvas, new TinyFaceDetectorOptions()))
      .then((faces) => {
        this.faceTrackingData.emit(new FaceApiTrackingData(faces));
      });
  }
}
