import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimeLapseComponent } from '@components/time-lapse/time-lapse.component';
import { environment } from '@environments/environment';
import { Photo } from '@models/photo';
import { Session, Sessions } from '@models/session';
import { TranslateService } from '@ngx-translate/core';
import { AttacheService } from '@services/attache';
import { Cable } from '@services/cable';
import { EventService } from '@services/event-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'lv-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class InfoBarComponent implements OnInit {
  @ViewChild(forwardRef(() => TimeLapseComponent), { static: true })
  timeLapse: TimeLapseComponent;
  session: Session;
  isConnected: boolean;
  token: string;

  get status(): Observable<string> {
    if (this.timeLapse && this.timeLapse.running) {
      return this.translateService.get('info-bar.component.running');
    }

    return this.translateService.get('info-bar.component.loading');
  }

  constructor(
    private sessionService: Sessions,
    private route: ActivatedRoute,
    private cable: Cable,
    private http: HttpClient,
    private attache: AttacheService,
    private translateService: TranslateService,
    private events: EventService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.token = params.token;
      this.session = this.sessionService.$findOne(`${params.token}/running`);
      this.session.asPromise().catch((session: Session) => console.error(session.$error));
    });

    this.events.publish('infobar:init');
  }

  timeLapsePhoto(photo: Photo) {
    this.attache.upload(this.session.attacheOptions, photo.file).then(
      (payload) => {
        const body = {
          attendance_photo: {
            photo: JSON.stringify(payload),
            face_tracking_data_attributes: [photo.faceTrackingData.json()]
          }
        };
        this.http
          .post(`${environment.apiBase}sessions/${this.session.token}/attendance_photos`, body)
          .toPromise()
          .catch((err) => console.error('failed creating attendance photo', err));
      },
      (err) => console.error('failed uploading attendance photo', err)
    );
  }

  close() {
    const cableParams = {
      channel: 'SessionRunningChannel',
      token: this.token
    };
    const channel = this.cable.subs.create(cableParams, {
      connected: () => {
        channel.perform('rebroadcast', { error: this.session.$error });
        channel.unsubscribe();
      },
      disconnected: () => {},
      received: () => {}
    });
  }
}
