import { Component, HostBinding, Input } from '@angular/core';
import { Event } from '@models/event';

@Component({
  selector: 'lv-attendance-timeline-event',
  styleUrls: ['attendance-timeline-event.component.sass'],
  template: ''
})
export class AttendanceTimelineEventComponent {
  @Input() event: Event;
  @Input() sessionStart: Date;
  @Input() sessionEnd: Date;

  @HostBinding('style.left')
  get percentage(): string {
    const startTime = this.sessionStart.getTime();
    const endTime = this.sessionEnd.getTime();
    const eventTime = this.event.createdAt.getTime();
    const p = (eventTime - startTime) / (endTime - startTime);

    return p * 100 + '%';
  }
}
