<div class="camera-feed-container" #container></div>
<button
  class="button is-primary is-fullwidth"
  [ngClass]="{ 'is-failure': camera.state !== CameraState.Activated }"
  (click)="onCameraAction()"
  *ngIf="showCapture"
>
  <span class="icon">
    <i lvIcon="camera"></i>
  </span>
  <span *ngIf="camera.state === CameraState.Activated" translate>app.capture-photo</span>
  <span *ngIf="camera.state !== CameraState.Activated" translate>app.reconnect-camera</span>
</button>
