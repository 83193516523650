import { Injectable } from '@angular/core';
import { TokenService } from '@services/token-service';

import { Resource, ResourceService } from './resource';

export class PhotoComment extends Resource {
  comment: string;
  commenterId: number;
  commenterType: string;
  attendancePhotoId: number;
}

@Injectable({ providedIn: 'root' })
export class PhotoComments extends ResourceService<PhotoComment> {
  constructor(tokenService: TokenService) {
    super(tokenService);
    this.base = 'photo_comments';
    this.ctor = PhotoComment;
  }
}
