import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@services/token-service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[lvLogout], button[lvLogout]'
})
export class LogoutDirective {
  constructor(private tokenService: TokenService, private router: Router) {}

  @HostListener('click') click() {
    this.tokenService.signOut().subscribe(
      () => this.router.navigateByUrl('auth/sign_in'),
      (err) => console.error(err)
    );
  }
}
