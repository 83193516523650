import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[lvI18nPlaceholder]'
})
export class I18nPlaceholderDirective implements OnInit {
  @Input() lvI18nPlaceholder: string;

  constructor(private element: ElementRef, private renderer: Renderer2, private translate: TranslateService) {}

  ngOnInit() {
    const native = this.element.nativeElement;
    this.translate
      .get(this.lvI18nPlaceholder)
      .subscribe((res) => this.renderer.setProperty(native, 'placeholder', res));
  }
}

@Directive({
  selector: '[lvI18nValue]'
})
export class I18nValueDirective implements OnInit {
  @Input() lvI18nValue: string;

  constructor(private element: ElementRef, private renderer: Renderer2, private translate: TranslateService) {}

  ngOnInit() {
    const native = this.element.nativeElement;
    this.translate.get(this.lvI18nValue).subscribe((res) => this.renderer.setProperty(native, 'value', res));
  }
}
