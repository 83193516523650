function nativeDetectorApiEnabled() {
  return 'FaceDetector' in window;
}

async function nativeDetectorSupported() {
  const fd = new FaceDetector();
  const canvas = document.createElement('canvas');
  return await fd
    .detect(canvas)
    .then(() => true)
    .catch((e) => (e.name === 'NotSupportedError' ? false : true));
}

export async function nativeDetectorAvailable() {
  return nativeDetectorApiEnabled() && (await nativeDetectorSupported());
}
