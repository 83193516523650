import { User } from '@models/user';

import { Policy } from './policy';

export abstract class ApplicationPolicy implements Policy {
  constructor(protected user: User) {}

  show(): boolean {
    return false;
  }

  create(): boolean {
    return false;
  }

  update(): boolean {
    return false;
  }

  destroy(): boolean {
    return false;
  }

  protected isStaff(): boolean {
    if (this.user.email) {
      this.user.email.endsWith('@weknowtraining.ca');
    }
    return false;
  }
}
