import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Tokbox } from '@models/tokbox';

@Component({
  selector: 'lv-tokbox-view',
  templateUrl: 'tokbox-view.component.html',
  styleUrls: ['tokbox-view.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class TokboxViewComponent implements OnInit, OnDestroy {
  @Input() connect: EventEmitter<null>;
  @Input() tokbox: Tokbox;
  @Input() resolution: '1280x960' | '1280x720' | '640x480' | '640x360' | '320x240' | '320x180' = '640x480';
  @Input() autoConnect = false;

  @ViewChild('me', { static: true }) meElement: ElementRef;
  @ViewChild('them', { static: true }) themElement: ElementRef;

  @HostBinding('class.is-connected') get connected(): boolean {
    return this.tokbox.connected;
  }

  ngOnInit() {
    const options: OT.PublisherProperties = {
      width: '100%',
      height: '100%',
      resolution: this.resolution
    };
    if (this.connect) {
      const subscription = this.connect.asObservable().subscribe(() => {
        this.tokbox.connect(this.themElement.nativeElement, this.meElement.nativeElement, options);
        subscription.unsubscribe();
      });
    } else if (this.autoConnect) {
      this.tokbox.connect(this.themElement.nativeElement, this.meElement.nativeElement, options);
    }
  }

  ngOnDestroy() {
    this.tokbox.disconnect();
  }
}
