import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Photo } from '@models/photo';
import { Session } from '@models/session';
import { AttacheService } from '@services/attache';
import * as ActionCable from '@rails/actioncable';

@Directive()
export abstract class AbstractVerifyPhotoDirective {
  @Input() session: Session;
  @Input() channel: ActionCable.Channel;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();

  photo: Photo;
  photoURL: SafeUrl;
  saving = false;

  constructor(private attache: AttacheService, private sanitizer: DomSanitizer) {}

  get showNextButton(): boolean {
    return !this.session.liveAgentConnected;
  }

  get nextDisabled(): boolean {
    return this.session.liveAgentConnected || this.saving || !this.photo;
  }

  canShowBack() {
    if (this.session.liveAgent) {
      return false;
    }
    return !this.session.idVerified && this.session.requiresIdentification;
  }

  done() {
    this.next.emit();
  }

  previous() {
    this.back.emit();
  }

  capture(photo: Photo) {
    this.saving = true;
    this.photo = photo;
    this.photoURL = this.sanitizer.bypassSecurityTrustUrl(photo.image);
    this.attache
      .upload(this.session.attacheOptions, photo.file)
      .then(
        (payload) => {
          this.photo.payload = payload;
          this.handlePhoto(this.photo);
          this.saving = false;
        },
        (err) => console.error('failed uploading photo', err)
      )
      .catch(() => (this.saving = false));
  }

  reset() {
    if (this.photo) {
      URL.revokeObjectURL(this.photo.image);
    }
    this.photo = null;
    this.saving = false;
  }

  abstract handlePhoto(photo: Photo);
}
