import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';

@Component({
  selector: 'lv-done-auditing',
  templateUrl: 'done-auditing.component.html',
  styleUrls: ['done-auditing.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DoneAuditingComponent {
  @Input() session: Session;
  @Input() passOrFail: string;
}
