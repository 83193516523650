import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { EventService } from '@services/event-service';

@Component({
  selector: 'lv-learner-integration',
  templateUrl: 'learner-integration.component.html',
  styleUrls: ['learner-integration.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class LearnerIntegrationComponent implements OnInit {
  @HostBinding('class.is-monitored') monitored = false;
  constructor(private events: EventService) {}

  ngOnInit() {
    this.events.of('infobar:init').subscribe(() => {
      setTimeout(() => (this.monitored = true), 0); // to avoid ExpressionChangedAfterItHasBeenCheckedError
    });
  }
}
