import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lv-script-toggle',
  styleUrls: ['script-toggle.component.sass'],
  templateUrl: 'script-toggle.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ScriptToggleComponent {
  @HostBinding('class.is-visible') _open = false;

  toggleScript() {
    this._open = !this._open;
  }
}
