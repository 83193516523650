import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';

@Component({
  selector: 'lv-verify-token-error',
  templateUrl: 'verify-token-error.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyTokenErrorComponent {
  @Input() session: Session;
  @Output() dismiss = new EventEmitter();

  done() {
    this.dismiss.emit();
  }

  tryAgain() {
    this.session.$error = null;
  }
}
