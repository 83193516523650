import { Injectable } from '@angular/core';
import * as ActionCable from '@rails/actioncable';
import { v4 as uuidv4 } from 'uuid';

import { Cable } from './cable';
import { Camera, CameraState } from './camera';

// You have to require like this, not import like above, otherwise it just won't work
const DetectRTC = require('detectrtc');

@Injectable({ providedIn: 'root' })
export class SystemCheck {
  browserName: string;
  microphone = false;
  speakers = false;
  webrtc = false;
  isConnected = false;
  pingChannel: ActionCable.Channel;

  get browser(): boolean {
    switch (this.browserName) {
      case 'chrome':
      case 'opera':
      case 'firefox':
        return true;
      case 'safari':
        return this.browserVersion.version >= 11;
      default:
        return false;
    }
  }

  get browserVersion() {
    return DetectRTC.browser;
  }

  constructor(private cable: Cable, private camera: Camera) {}

  start(): Promise<void> {
    return new Promise((resolve) => {
      DetectRTC.load(() => {
        this.browserName = DetectRTC.browser.name.toLowerCase();
        this.microphone = DetectRTC.hasMicrophone;
        this.speakers = DetectRTC.hasSpeakers || this.browserName === 'firefox';
        this.webrtc = DetectRTC.isWebRTCSupported;
        resolve();
      });
    });
  }

  stop() {
    this.pingChannel?.unsubscribe();
  }

  testWebsockets(): Promise<boolean> {
    return new Promise((resolve) => {
      const uuid = uuidv4();
      const params = {
        channel: 'PingChannel',
        uuid: uuid
      };
      this.pingChannel = this.cable.consumer.subscriptions.create(params, {
        connected: () => {
          this.isConnected = true;
          this.pingChannel.perform('ping', { uuid: uuid });
        },
        disconnected: () => (this.isConnected = false),
        received: (data: any) => {
          resolve(data.pong && data.uuid === uuid);
        }
      });
    });
  }

  testWebcam(): Promise<boolean> {
    return new Promise((resolve) => {
      if (DetectRTC.hasWebcam) {
        this.camera.activate().then(
          () => {
            this.camera.state = CameraState.Activated;
            resolve(true);
          },
          (err) => {
            console.error('failed activating camera', err);
            this.camera.state = CameraState.Deactivated;
            resolve(false);
          }
        );
      } else {
        this.camera.state = CameraState.Uninitialized;
        resolve(false);
      }
    });
  }
}
