import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Photo } from '@models/photo';
import { Camera, CameraState, CameraStream } from '@services/camera';
import { noop } from 'underscore';

@Component({
  selector: 'lv-camera-feed',
  templateUrl: 'camera-feed.component.html',
  styleUrls: ['camera-feed.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CameraFeedComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: true, read: ElementRef }) containerElement: ElementRef;

  @Input() facing = 'user';
  @Input() filename = 'capture.png';
  @Input() keepRunning = false;
  @Input() showCapture = true;

  @Output() capture = new EventEmitter<Photo>();

  CameraState = CameraState;
  stream: CameraStream;

  get container(): HTMLElement {
    return this.containerElement.nativeElement;
  }

  constructor(private zone: NgZone, public camera: Camera) {}

  ngOnInit() {
    this.stream = this.camera.streamTo(this.container, noop, this.facing);
  }

  ngOnDestroy() {
    if (this.keepRunning) {
      return;
    }
    if (this.stream) {
      this.stream.stop();
    }
  }

  emit() {
    this.camera.snapBlob(this.stream).then((blob) => {
      this.zone.run(() => {
        const photo = new Photo(blob, this.filename);
        this.capture.emit(photo);
      });
    });
  }

  onCameraAction() {
    switch (this.camera.state) {
      case CameraState.Activated:
        this.emit();
        break;
      case CameraState.Deactivated:
      case CameraState.Uninitialized:
        this.stream = this.camera.streamTo(this.container, noop, this.facing);
        break;
    }
  }
}
