import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractBiometricsDirective } from '@directives/abstract-biometrics.directive';
import { BiometricsErrorComponent } from '@components/biometrics-error/biometrics-error.component';
import { Learner } from '@models/learner';
import { Session } from '@models/session';
import { Cable } from '@services/cable';
import { OverlayService } from '@services/overlay';
import * as ActionCable from '@rails/actioncable';

interface BiometricsHandledData {
  trained?: boolean;
  verified?: boolean;
  failed?: boolean;
}

interface SessionChannelData {
  biometrics?: BiometricsHandledData;
}

@Component({
  selector: 'lv-verify-biometrics',
  styleUrls: ['verify-biometrics.component.sass'],
  templateUrl: 'verify-biometrics.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyBiometricsComponent implements OnInit, OnDestroy {
  @Input() learner: Learner;
  @Input() session: Session;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() restart = new EventEmitter();
  @ViewChild('biometrics', { static: true }) biometrics: AbstractBiometricsDirective;

  channel: ActionCable.Channel;
  biometricsData: BiometricsHandledData;
  verifying = false;
  failedCount = 0;
  showKeyboardHelp = false;

  get verified(): boolean {
    return (
      this.biometricsData &&
      (this.biometricsData.trained || this.biometricsData.verified) &&
      !this.biometricsData.failed
    );
  }

  get registerOrAuth(): 'authorize' | 'register' {
    return this.learner.biometricsTrained ? 'authorize' : 'register';
  }

  get submitText(): string {
    return `verify-biometrics.component.${this.submitOrVerified}`;
  }

  get submitOrVerified(): 'submit' | 'verified' | 'accepted' {
    if (this.verified) {
      return this.learner.biometricsTrained ? 'verified' : 'accepted';
    }

    return 'submit';
  }

  get photoRequiredOrTaken(): 'photoAlreadyTaken' | 'photoRequired' {
    if (this.session.requiresIdentification) {
      return 'photoAlreadyTaken';
    }

    return 'photoRequired';
  }

  get forceTraining(): boolean {
    return !this.learner.biometricsTrained;
  }

  constructor(private cable: Cable, private overlayService: OverlayService) {}

  ngOnInit() {
    this.failedCount = 0;
    const cableParams = {
      channel: 'SessionChannel',
      token: this.session.token
    };

    const resetEmitter = new EventEmitter();
    resetEmitter.subscribe(() => this.reset());

    this.channel = this.cable.create(cableParams, (data: SessionChannelData) => {
      if (data.biometrics) {
        this.verifying = false;
        this.biometricsData = data.biometrics;

        if (this.biometricsData.failed) {
          this.failedCount++;
          this.overlayService.open(BiometricsErrorComponent, {
            allowHardRestart: this.failedCount > 2,
            restart: this.restart,
            reset: resetEmitter
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  }

  canShowBack() {
    if (this.session.liveAgent) {
      return false;
    }
    return !this.session.idVerified && this.session.requiresIdentification;
  }

  done() {
    this.next.emit();
  }

  previous() {
    this.back.emit();
  }

  reset() {
    this.verifying = false;
    this.biometrics.reset();
  }

  verify() {
    this.verifying = true;
    this.biometrics.verify(this.channel, this.forceTraining);
  }

  verifyDisabled(): boolean {
    if (this.biometrics) {
      return !this.biometrics.ready() || this.verified || this.verifying;
    }
    return true;
  }

  biometricsUpdated(data: any) {
    this.channel.perform('rebroadcast', { biometricsUpdate: data });
  }
}
