import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[lvIcon]'
})
export class IconDirective implements OnInit {
  lastIcon: string;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  get native(): HTMLElement {
    return this.element.nativeElement;
  }

  addClass(klass: string) {
    this.renderer.addClass(this.native, klass);
  }

  ngOnInit() {
    this.addClass('fa');
  }

  @Input() set lvIcon(value: string) {
    if (this.lastIcon) {
      this.renderer.removeClass(this.native, this.lastIcon);
    }
    this.lastIcon = `fa-${value}`;
    this.addClass(this.lastIcon);
  }
}
