import { Injectable, NgZone } from '@angular/core';
import { environment } from '@environments/environment';
import * as ActionCable from '@rails/actioncable';

type ReceivedFn = (obj: Object) => void;

@Injectable({ providedIn: 'root' })
export class Cable {
  consumer: ActionCable.Cable;

  constructor(private zone: NgZone) {
    this.consumer = ActionCable.createConsumer(environment.wss);
  }

  get subs(): ActionCable.Subscriptions {
    return this.consumer.subscriptions;
  }

  create(channel: string | ActionCable.ChannelNameWithParams, fn: ReceivedFn): ActionCable.Channel {
    // eslint-disable-next-line
    const that = this;

    return this.subs.create(channel, {
      connected: function () {
        that.zone.run(() => {
          this.isConnected = true;
        });
      },
      disconnected: function () {
        that.zone.run(() => {
          this.isConnected = false;
        });
      },
      received: (obj: Object) => {
        this.zone.run(() => {
          fn(obj);
        });
      }
    });
  }
}
