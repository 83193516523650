<section lvCloseOverlay withKeyboard="esc">
  <aside>
    <div class="icon"></div>
    <p translate>session-failed-reason.component.fail-user</p>
    <p><b>{{ session.learner.name }}</b></p>
    <button class="button is-failure is-fullwidth" [disabled]="failureForm.invalid" (click)="submit()" translate>app.fail</button>
    <button class="button is-secondary is-fullwidth" lvCloseOverlay translate>app.cancel</button>
  </aside>
  <main>
    <form #failureForm="ngForm">
      <div class="field has-addons">
        <span class="label" translate>session-failed-reason.component.reasons</span>
      </div>
      <div class="field" *ngFor="let reason of cannedReasons; let i = index">
        <div class="control checkbox">
          <input lvReasonCheck type="checkbox" id="reason_{{ i }}" [index]="i" (change)="updateReasons()">
          <label for="reason_{{ i }}">{{ reason }}</label>
        </div>
      </div>
      <div class="control">
        <input type="hidden" required name="reason" [ngModel]="reason">
      </div>
    </form>
  </main>
</section>
