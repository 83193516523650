import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccountFormComponent } from '@components/account-form/account-form.component';
import { AccountIndexComponent } from '@components/account-index/account-index.component';
import { AgentFeedComponent } from '@components/agent-feed/agent-feed.component';
import { AgentIndexComponent } from '@components/agent-index/agent-index.component';
import { AgentVerifyAttendanceComponent } from '@components/agent-verify-attendance/agent-verify-attendance.component';
import { AgentVerifyBiometricsComponent } from '@components/agent-verify-biometrics/agent-verify-biometrics.component';
import { AgentVerifyIdComponent } from '@components/agent-verify-id/agent-verify-id.component';
import { AttendanceTimelineEventComponent } from '@components/attendance-timeline-event/attendance-timeline-event.component';
import {
  AttendanceTimelineAnomalyComponent
} from '@components/attendance-timline-anomaly/attendance-timeline-anomaly.component';
import {
  AttendanceTimelineSessionComponent
} from '@components/attendance-timline-session/attendance-timeline-session.component';
import { AttendanceTimelineComponent } from '@components/attendance-timline/attendance-timeline.component';
import {
  AutomatedAttendanceOptionsComponent
} from '@components/automated-attendance-options/automated-attendance-options.component';
import { BiometricsErrorComponent } from '@components/biometrics-error/biometrics-error.component';
import { CameraFeedComponent } from '@components/camera-feed/camera-feed.component';
import { CaptionedImageComponent } from '@components/captioned-image/captioned-image.component';
import { ClaimsListItemComponent } from '@components/claims-list-item/claims-list-item.component';
import { ClaimsListComponent } from '@components/claims-list/claims-list.component';
import { CopyrightComponent } from '@components/copyright/copyright.component';
import { DashboardHeaderComponent } from '@components/dashboard-header/dashboard-header.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { DismissConfirmComponent } from '@components/dismiss-confirm/dismiss-confirm.component';
import { DoneAuditingComponent } from '@components/done-auditing/done-auditing.component';
import { EditProfileComponent } from '@components/edit-profile/edit-profile.component';
import { FaceboxBiometricsComponent } from '@components/facebox-biometrics/facebox-biometrics.component';
import { FlagPhotoComponent } from '@components/flag-photo/flag-photo.component';
import { InfoBarComponent } from '@components/info-bar/info-bar.component';
import { LearnerFeedComponent } from '@components/learner-feed/learner-feed.component';
import { LearnerIntegrationComponent } from '@components/learner-integration/learner-integration.component';
import { LearnerVerifyComponent } from '@components/learner-verify/learner-verify.component';
import { LoginFormComponent } from '@components/login-form/login-form.component';
import { MenuToggleComponent } from '@components/menu-toggle/menu-toggle.component';
import { OverlaysComponent } from '@components/overlays/overlays.component';
import { PDFDialogComponent } from '@components/pdf-dialog/pdf-dialog.component';
import { ScriptToggleComponent } from '@components/script-toggle/script-toggle.component';
import { SearchInputComponent } from '@components/search-input/search-input.component';
import { SessionDetailsComponent } from '@components/session-details/session-details.component';
import {
  ReasonCheckboxDirective,
  SessionFailedReasonComponent
} from '@components/session-failed-reason/session-failed-reason.component';
import {
  ImgBufferDirective,
  SessionReviewAttendanceComponent
} from '@components/session-review-attendance/session-review-attendance.component';
import { SessionReviewIdComponent } from '@components/session-review-id/session-review-id.component';
import { SessionReviewComponent } from '@components/session-review/session-review.component';
import { SessionComponent } from '@components/session/session.component';
import { SiteNavComponent } from '@components/site-nav/site-nav.component';
import { SystemCheckComponent } from '@components/system-check/system-check.component';
import { SystemTestItemComponent } from '@components/system-test-item/system-test-item.component';
import { TimeLapseComponent } from '@components/time-lapse/time-lapse.component';
import { TokboxEmbedComponent } from '@components/tokbox-embed/tokbox-embed.component';
import { TokboxViewComponent } from '@components/tokbox-view/tokbox-view.component';
import { UserDetailsComponent } from '@components/user-details/user-details.component';
import { UserListComponent } from '@components/user-list/user-list.component';
import { VerifyAgentComponent } from '@components/verify-agent/verify-agent.component';
import { VerifyBiometricsComponent } from '@components/verify-biometrics/verify-biometrics.component';
import { VerifyFailureComponent } from '@components/verify-failure/verify-failure.component';
import { VerifyIdComponent } from '@components/verify-id/verify-id.component';
import { VerifyInstructionsComponent } from '@components/verify-instructions/verify-instructions.component';
import { VerifyPhotoComponent } from '@components/verify-photo/verify-photo.component';
import { VerifyReadyComponent } from '@components/verify-ready/verify-ready.component';
import { VerifySystemComponent } from '@components/verify-system/verify-system.component';
import { VerifyTokenErrorComponent } from '@components/verify-token-error/verify-token-error.component';
import { WalkStepBiometricsComponent } from '@components/walk-step-biometrics/walk-step-biometrics.component';
import { WalkStepCompleteComponent } from '@components/walk-step-complete/walk-step-complete.component';
import { WalkStepIDComponent } from '@components/walk-step-id/walk-step-id.component';
import { WalkStepIntroComponent } from '@components/walk-step-intro/walk-step-intro.component';
import { WalkStepPhotoComponent } from '@components/walk-step-photo/walk-step-photo.component';
import { WebhookFormComponent } from '@components/webhook-form/webhook-form.component';
import { WebhooksComponent } from '@components/webhooks/webhooks.component';
import {
  CloseOverlayAfterDirective,
  CloseOverlayClickDirective,
  CloseOverlayKeyboardDirective
} from '@directives/close-overlay.directives';
import { I18nPlaceholderDirective, I18nValueDirective } from '@directives/i18n.directives';
import { IconDirective } from '@directives/icon.directive';
import { LogoutDirective } from '@directives/logout.directive';
import { PermitDirective } from '@directives/permit.directive';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DurationPipe } from '@pipes/duration.pipe';
import { Camera, WebRTCCamera } from '@services/camera';
import { CameraDetectorService } from '@services/camera-detector.service';
import { FaceApiTrackerService } from '@services/face-api-tracker.service';
import { FaceTracker } from '@services/face-tracker';
import { NativeFaceTrackerService } from '@services/native-face-tracker.service';
import { NewrelicErrorHandler } from '@services/newrelic-error-handler';
import { LvMissingTranslationHandler } from '@utils/missing-translation-handler';
import { FileSaverModule } from 'ngx-filesaver';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { from, Observable } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DetailsPanelComponent } from './components/details-panel/details-panel.component';

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

export function detectCamera(cameraDetectorService: CameraDetectorService) {
  return () => cameraDetectorService.detectNativeCamera();
}

export function getFaceTracker(cameraDetectorService: CameraDetectorService): FaceTracker {
  if (cameraDetectorService.nativeCameraAvailable) {
    return new NativeFaceTrackerService();
  }
  return new FaceApiTrackerService();
}

export function getCamera(): Camera {
  return new WebRTCCamera();
}

@NgModule({
  declarations: [
    AccountFormComponent,
    AccountIndexComponent,
    AgentFeedComponent,
    AgentIndexComponent,
    AgentVerifyAttendanceComponent,
    AgentVerifyBiometricsComponent,
    AgentVerifyIdComponent,
    AppComponent,
    AttendanceTimelineAnomalyComponent,
    AttendanceTimelineEventComponent,
    AttendanceTimelineComponent,
    AttendanceTimelineSessionComponent,
    AutomatedAttendanceOptionsComponent,
    BiometricsErrorComponent,
    CameraFeedComponent,
    CaptionedImageComponent,
    ClaimsListComponent,
    ClaimsListItemComponent,
    CloseOverlayAfterDirective,
    CloseOverlayClickDirective,
    CloseOverlayKeyboardDirective,
    CopyrightComponent,
    DashboardComponent,
    DashboardHeaderComponent,
    DetailsPanelComponent,
    DismissConfirmComponent,
    DoneAuditingComponent,
    DurationPipe,
    EditProfileComponent,
    FaceboxBiometricsComponent,
    FlagPhotoComponent,
    I18nPlaceholderDirective,
    I18nValueDirective,
    IconDirective,
    ImgBufferDirective,
    InfoBarComponent,
    LearnerFeedComponent,
    LearnerIntegrationComponent,
    LearnerVerifyComponent,
    LoginFormComponent,
    LogoutDirective,
    MenuToggleComponent,
    OverlaysComponent,
    PDFDialogComponent,
    PermitDirective,
    ReasonCheckboxDirective,
    ScriptToggleComponent,
    SearchInputComponent,
    SessionComponent,
    SessionDetailsComponent,
    SessionFailedReasonComponent,
    SessionReviewAttendanceComponent,
    SessionReviewComponent,
    SessionReviewIdComponent,
    SiteNavComponent,
    SystemCheckComponent,
    SystemTestItemComponent,
    TimeLapseComponent,
    TokboxEmbedComponent,
    TokboxViewComponent,
    UserDetailsComponent,
    UserListComponent,
    VerifyAgentComponent,
    VerifyBiometricsComponent,
    VerifyFailureComponent,
    VerifyIdComponent,
    VerifyInstructionsComponent,
    VerifyPhotoComponent,
    VerifyReadyComponent,
    VerifySystemComponent,
    VerifyTokenErrorComponent,
    WalkStepBiometricsComponent,
    WalkStepCompleteComponent,
    WalkStepIDComponent,
    WalkStepIntroComponent,
    WalkStepPhotoComponent,
    WebhookFormComponent,
    WebhooksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    FileSaverModule,
    FilterPipeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LvMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: detectCamera,
      deps: [CameraDetectorService],
      multi: true
    },
    {
      provide: FaceTracker,
      useFactory: getFaceTracker,
      deps: [CameraDetectorService]
    },
    {
      provide: Camera,
      useFactory: getCamera
    },
    {
      provide: ErrorHandler,
      useClass: NewrelicErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
