import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';
import { Cable } from '@services/cable';
import * as ActionCable from '@rails/actioncable';

interface SessionChannelData {
  biometricsUpdate?: any;
  biometrics?: BiometricsHandledData;
}

interface BiometricsHandledData {
  trained?: boolean;
  verified?: boolean;
  failed?: boolean;
}

@Component({
  selector: 'lv-agent-verify-biometrics',
  templateUrl: './agent-verify-biometrics.component.html',
  styleUrls: ['./agent-verify-biometrics.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AgentVerifyBiometricsComponent implements OnInit, OnDestroy {
  @Input() session: Session;

  channel: ActionCable.Channel;

  typed = 0;
  length = 1;
  verified = false;
  failed = false;

  constructor(private cable: Cable) {}

  ngOnInit() {
    const cableParams = {
      channel: 'SessionChannel',
      token: this.session.token
    };
    this.channel = this.cable.create(cableParams, (data: SessionChannelData) => {
      if (data.biometrics) {
        this.verified = data.biometrics.verified === true;
        this.failed = data.biometrics.failed === true;
      }

      if (data.biometricsUpdate) {
        this.typed = data.biometricsUpdate.typed;
        this.length = data.biometricsUpdate.length;
        this.verified = this.failed = false;
      }
    });
  }

  ngOnDestroy() {
    this.channel.unsubscribe();
  }
}
