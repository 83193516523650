<div class="session-info">
  <h4 translate>session.learner</h4>
  <div class="session-info__item">
    <p translate>learner.name</p>
    <p>{{ learner?.name }}</p>
  </div>

  <div *ngIf="learner?.email" class="session-info__item">
    <p translate>learner.email</p>
    <p>{{ learner?.email }}</p>
  </div>

  <div class="session-info__photos" *ngIf="session.idVerified">
    <div class="session-info__item">
      <p translate>session-details.component.face-photo</p>
      <img [src]="identification.facePhotoUrl" />
    </div>
    <div class="session-info__item">
      <p translate>session-details.component.photo-id</p>
      <img [src]="identification.idPhotoUrl" />
    </div>
  </div>
</div>
<div class="session-info">
  <h4 translate>session.details</h4>
  <div class="session-info__item">
    <p translate>session.website</p>
    <p>{{ session.domain }}</p>
  </div>
  <div class="session-info__item">
    <p translate>session.name</p>
    <p>{{ session.name }}</p>
  </div>
  <div class="session-info__item">
    <p translate>session.created-at</p>
    <p>{{ session.createdAt | date: 'medium' }}</p>
  </div>
  <div class="session-info__item" *ngIf="session.finishedAt">
    <p translate>session.finished-at</p>
    <p>{{ session.finishedAt | date: 'medium' }}</p>
  </div>
  <div *ngIf="tokboxEnabled" class="session-info__item session-info__item--agent">
    <div class="field is-grouped">
      <p class="control" translate>session.live-agent</p>
      <span class="icon control" [ngClass]="{ 'is-success': session.tokbox.connected }">
        <i lvIcon="user"></i>
      </span>
    </div>

    <lv-tokbox-view [tokbox]="session.tokbox" [autoConnect]="true"></lv-tokbox-view>
  </div>
</div>

<div class="session-actions">
  <h4 translate>session-details.component.verify.{{ verifyLabel() }}</h4>
  <button class="button is-success is-medium" (click)="pass()" [disabled]="!passEnabled()" translate>app.pass</button>
  <button class="button is-failure is-medium" (click)="fail()" [disabled]="failDisabled()" translate>app.fail</button>
</div>
