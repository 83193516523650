import { Pipe, PipeTransform } from '@angular/core';

import humanizeDuration from 'humanize-duration';

const short = humanizeDuration.humanizer({
  language: 'short',
  languages: {
    short: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
      decimal: '.'
    }
  }
});

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  config = {
    units: ['d', 'h', 'm', 's'],
    spacer: '',
    delimiter: ' ',
    round: true
  };

  transform(value: number): any {
    return short(value, this.config);
  }
}
