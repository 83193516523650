<ng-template [ngIf]="session?.$resolved">
  <ng-template [ngIf]="session.$error">
    <aside class="lv-learner-verify__sidebar">
      <div class="sidebar-branding"></div>
      <ul class="sidebar-checklist">
        <li class="is-error">
          <span translate>app.error</span>
        </li>
      </ul>
    </aside>
    <section class="lv-learner-verify__body">
      <lv-verify-token-error (dismiss)="close()" [session]="session"></lv-verify-token-error>
    </section>
  </ng-template>

  <ng-template [ngIf]="!session.$error">
    <aside class="lv-learner-verify__sidebar">
      <div class="sidebar-branding"></div>
      <ul class="sidebar-checklist">
        <li [ngClass]="classFor(1)">
          <span translate>learner-verify.component.instructions</span>
        </li>
        <li [ngClass]="classFor(2)">
          <span translate>learner-verify.component.system-test</span>
        </li>
        <li *ngIf="session.requiresIdentification" [ngClass]="classFor(3)">
          <span translate>learner-verify.component.user-photo</span>
        </li>
        <li *ngIf="session.requiresIdentification" [ngClass]="classFor(4)">
          <span translate>learner-verify.component.id-photo</span>
        </li>
        <li *ngIf="session.liveAgent" [ngClass]="classFor(5)">
          <span translate>learner-verify.component.live-agent</span>
        </li>
        <li *ngIf="session.biometrics" [ngClass]="classFor(6)">
          <span translate>learner-verify.component.biometrics</span>
        </li>
        <li [ngClass]="classFor(7)">
          <span translate>learner-verify.component.ready</span>
        </li>
      </ul>
      <lv-tokbox-view *ngIf="session.tokbox" [tokbox]="session.tokbox" [connect]="connectLiveAgent"></lv-tokbox-view>
      <button class="button is-secondary dismiss-btn" (click)="confirmDismiss()">
        <span class="icon">
          <i lvIcon="times"></i>
        </span>
        <span translate>app.close</span>
      </button>
    </aside>
    <section class="lv-learner-verify__body" [ngSwitch]="step">
      <lv-verify-instructions *ngSwitchCase="1" (next)="next()" [session]="session" [learner]="learner"></lv-verify-instructions>
      <lv-verify-system *ngSwitchCase="2" (next)="next()" [session]="session"></lv-verify-system>
      <ng-template [ngIf]="session.requiresIdentification">
        <lv-verify-photo *ngSwitchCase="3" (next)="next()" [session]="session" [channel]="channel"></lv-verify-photo>
        <lv-verify-id *ngSwitchCase="4" (next)="next()" (back)="back()" [session]="session" [channel]="channel"></lv-verify-id>
      </ng-template>
      <ng-template [ngIf]="session.liveAgent">
        <lv-verify-agent *ngSwitchCase="5" [session]="session" [connect]="connectLiveAgent"></lv-verify-agent>
      </ng-template>
      <ng-template [ngIf]="session.biometrics">
        <lv-verify-biometrics *ngSwitchCase="6" [session]="session" [learner]="session.learner" (next)="next()" (back)="back()"
          (restart)="restart()"></lv-verify-biometrics>
      </ng-template>
      <lv-verify-ready *ngSwitchCase="7" [session]="session" (next)="start()"></lv-verify-ready>
    </section>
  </ng-template>
</ng-template>
