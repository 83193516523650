<lv-system-test-item
  icon="cog"
  [working]="browserCompatibility()"
  [labelParams]="{ url: 'https://www.google.com/chrome/' }"
  key="browser-compatibility"
>
  <lv-system-test-item
    [icon]="browserName"
    [labelParams]="browserVersion"
    [working]="browserOk"
    key="browser"
  ></lv-system-test-item>
  <lv-system-test-item icon="plug" [working]="websocketsOk" key="websockets"></lv-system-test-item>
  <lv-system-test-item icon="comments-o" [working]="webrtcOk" key="webrtc"></lv-system-test-item>
</lv-system-test-item>

<lv-system-test-item icon="video-camera" [working]="webcamOk" key="webcam"></lv-system-test-item>
<lv-system-test-item icon="microphone" [working]="microphoneOk" key="microphone" *ngIf="audio"></lv-system-test-item>
<lv-system-test-item icon="volume-up" [working]="speakersOk" key="speakers" *ngIf="audio"></lv-system-test-item>
<button *ngIf="!everythingWorks()" class="button is-bordered delay-reveal" (click)="onRetry()" translate>
  app.retry
</button>
