import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AutomatedAttendanceOptions } from '@models/session';

@Component({
  selector: 'lv-automated-attendance-options',
  templateUrl: 'automated-attendance-options.component.html',
  styleUrls: ['automated-attendance-options.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AutomatedAttendanceOptionsComponent {
  @Input() options: AutomatedAttendanceOptions;
}
