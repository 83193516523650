import { AttachePayload } from '@services/attache';

import { FaceTrackingData } from './face-tracking-data';

export class Photo {
  payload: AttachePayload;

  private _image: string;
  private _file: File;

  get image(): string {
    if (this._image) {
      return this._image;
    }
    this._image = URL.createObjectURL(this.blob);
    return this._image;
  }

  get file(): File {
    if (this._file) {
      return this._file;
    }
    this._file = new File([this.blob], this.filename);
    return this._file;
  }

  constructor(public blob: Blob, public filename: string, public faceTrackingData?: FaceTrackingData) {}
}
