<ng-template [ngIf]="session?.$resolved">
  <ng-template [ngIf]="session.$error">
    <lv-verify-token-error (dismiss)="close()"></lv-verify-token-error>
  </ng-template>

  <ng-template [ngIf]="!session.$error">
    <p>{{ status | async }}</p>
    <lv-time-lapse [interval]="20" [anomalyInterval]="5" (capture)="timeLapsePhoto($event)"></lv-time-lapse>
  </ng-template>
</ng-template>
