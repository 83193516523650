import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class LvMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const currentLang = params.translateService.currentLang;
    const key = params.key;
    console.error(`missing translation for ${currentLang}.${key}`);
    return key;
  }
}
