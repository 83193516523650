import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AbstractBiometricsDirective } from '@directives/abstract-biometrics.directive';
import { Photo } from '@models/photo';
import { AttacheService } from '@services/attache';
import * as ActionCable from '@rails/actioncable';

@Component({
  selector: 'lv-facebox-biometrics',
  templateUrl: 'facebox-biometrics.component.html',
  styleUrls: ['facebox-biometrics.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class FaceboxBiometricsComponent extends AbstractBiometricsDirective implements OnInit {
  photo: Photo;
  photoURL: SafeUrl;
  saving = false;

  constructor(private attache: AttacheService, private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    if (this.session.facePhoto) {
      this.setPhoto(this.session.facePhoto);
    }
  }

  ready(): boolean {
    return !!this.photo && !this.saving;
  }

  capture(photo: Photo) {
    this.saving = true;
    this.setPhoto(photo);
    this.attache
      .upload(this.session.attacheOptions, photo.file)
      .then(
        (payload) => {
          this.photo.payload = payload;
          this.saving = false;
        },
        (err) => console.error('failed uploading photo', err)
      )
      .catch(() => (this.saving = false));
  }

  reset() {
    if (this.photo) {
      URL.revokeObjectURL(this.photo.image);
    }
    this.photo = null;
    this.saving = false;
  }

  verify(channel: ActionCable.Channel, forceTraining: boolean) {
    channel.perform('handle_biometrics', {
      force_training: forceTraining,
      biometric_signature: {
        type: 'FaceboxSignature',
        data: JSON.stringify(this.photo.payload)
      }
    });
  }

  private setPhoto(photo: Photo) {
    this.photo = photo;
    this.photoURL = this.sanitizer.bypassSecurityTrustUrl(photo.image);
  }
}
