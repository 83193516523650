import { AttachePayload } from '@services/attache';

export class AttacheOptions {
  geometry: string;
  uploadurl: string;
  downloadurl: string;
  uuid: string;
  expiration: number;
  hmac: string;

  constructor(options) {
    Object.assign(this, options);
  }

  urlFor(payload: AttachePayload, geometry = 'original'): string {
    const parts = payload.path.split('/');
    const filename = parts.pop().split(/[#?]/).shift();
    parts.push(encodeURIComponent(geometry));
    parts.push(encodeURIComponent(filename));
    const path = parts.join('/');
    return `${this.downloadurl}/${path}`;
  }
}
