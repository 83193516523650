import { Deserialize } from './decorators';
import { Resource } from './resource';

export type AnomalyKind = 'absent' | 'assistance';

export class Anomaly extends Resource {
  @Deserialize((v) => v && new Date(v))
  startedAt: Date;

  @Deserialize((v) => v && new Date(v))
  endedAt: Date;

  sessionId: number;
  duration: number; // seconds
  kind: AnomalyKind;
}
