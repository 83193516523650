import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'lv-details-panel',
  templateUrl: './details-panel.component.html',
  styleUrls: ['./details-panel.component.scss']
})
export class DetailsPanelComponent implements AfterViewInit, OnChanges {
  @ViewChild('details', { read: ElementRef }) detailsRef: ElementRef;

  @Input() label = 'details-panel.label';
  @Input() open: boolean;

  get detailsElm(): HTMLDetailsElement {
    return this.detailsRef?.nativeElement;
  }

  ngAfterViewInit() {
    if (this.open) {
      this.detailsElm.setAttribute('open', 'true');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.open) {
      this.detailsElm?.setAttribute('open', 'true');
    } else {
      this.detailsElm?.removeAttribute('open');
    }
  }
}
