<h5 translate>webhooks.component.webhooks</h5>
<table class="table is-striped" *ngIf="account.webhooks.length">
  <thead>
    <th translate>webhook.url</th>
    <th translate>webhook.event</th>
    <th translate>webhook.signingToken</th>
    <th></th>
  </thead>
  <tbody>
    <tr *ngFor="let webhook of account.webhooks">
      <td (click)="edit(webhook)">{{ webhook.url }}</td>
      <td>{{ webhook.event }}</td>
      <td>{{ webhook.signingToken }}</td>
      <td>
        <button class="button is-small is-failure" (click)="destroy(webhook)">
          <span class="icon is-small">
            <i lvIcon="trash"></i>
          </span>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<div class="field is-grouped is-grouped-right">
  <button class="button is-primary control" (click)="newWebhook()">
    <span translate>webhooks.component.new</span>
    <span class="icon">
      <i lvIcon="plus"></i>
    </span>
  </button>
</div>
