<div class="learner-photos control">
  <h3 class="learner-status">
    <span translate>session-review-id.component.title</span>
    <span translate [ngClass]="{ 'is-success': session?.idVerificationStatus == 'passed', 'is-failure': session?.idVerificationStatus == 'failed' }">session-review.component.verified.{{ session?.idVerificationStatus }}
      <div class="verification-reasons">
        <p *ngFor="let reason of verificationReasons()" [innerHTML]="reason"></p>
      </div>
    </span>
  </h3>
  <table class="control table is-bordered is-narrow">
    <thead>
      <tr>
        <td translate>session-review-id.component.name</td>
        <td translate>session-review-id.component.id</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ session?.learner?.name }}</td>
        <td>{{ session?.learner?.id }}</td>
      </tr>
    </tbody>
  </table>
  <div class="photo control">
    <img *ngIf="identification" [src]="identification.facePhotoUrl" />
    <p class="label" translate>session-review-id.component.face-photo</p>
  </div>
  <div class="photo control">
    <img *ngIf="identification" [src]="identification.idPhotoUrl" />
    <p class="label" translate>session-review-id.component.id-photo</p>
  </div>
</div>
