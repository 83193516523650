import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';

@Component({
  selector: 'lv-verify-ready',
  styleUrls: ['verify-ready.component.sass'],
  templateUrl: 'verify-ready.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyReadyComponent {
  @Input() session: Session;
  @Output() next = new EventEmitter();

  get agentOrNone(): string {
    return this.session.liveAgent ? 'agent' : 'no-agent';
  }

  get displayAutomatedAttendanceOptions(): boolean {
    const serviceEnabled = this.session.services.includes('automated_attendance');
    const optionsEnabled = this.session.serviceOptions.automatedAttendance.anythingEnabled;
    return serviceEnabled && optionsEnabled;
  }

  done() {
    this.next.emit();
  }
}
