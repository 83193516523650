<td>
  <span [attr.title]="learner.email">{{ learner.name }}</span>
</td>
<td>{{ session.domain }}</td>
<td>{{ session.name }}</td>
<td>{{ waitTime | duration }}</td>
<td>
  <button *ngIf="session.unclaimed" class="button is-secondary row-button" (click)="claim()" translate>
    claims-list-item.component.claim
  </button>
  <a
    *ngIf="session.claimed"
    class="button is-primary row-button"
    routerLink="/sessions/{{ session.id }}"
    href="/sessions/{{ session.id }}"
    translate
  >
    app.open
  </a>
</td>
