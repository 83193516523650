import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractVerifyPhotoDirective } from '@directives/abstract-verify-photo.directive';
import { Photo } from '@models/photo';
import { AttacheService } from '@services/attache';

@Component({
  selector: 'lv-verify-photo',
  styleUrls: ['verify-photo.component.sass'],
  templateUrl: 'verify-photo.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyPhotoComponent extends AbstractVerifyPhotoDirective {
  constructor(attache: AttacheService, sanitizer: DomSanitizer) {
    super(attache, sanitizer);
  }

  handlePhoto(photo: Photo) {
    this.channel.perform('rebroadcast', { freshFacePhoto: photo.payload });
    this.session.facePhoto = photo;
  }
}
