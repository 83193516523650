<div class="body-header">
  <h3 translate>verify-token-error.component.{{ this.session.$error.status }}.title</h3>
</div>
<div class="body-content">
  <div class="content">
    <h5>
      <strong translate>verify-token-error.component.{{ this.session.$error.status }}.content-title</strong>
    </h5>
    <p translate>verify-token-error.component.content-body</p>
    <p *ngIf="this.session.$error.biometricsFailed">
      <button class="button is-primary is-fullwidth" (click)="tryAgain()" translate>app.try-again</button>
    </p>
  </div>
</div>
<div class="body-footer">
  <button class="button is-failure is-fullwidth next-button" (click)="done()">
    <span translate>app.close</span>
    <span class="icon">
      <i lvIcon="times"></i>
    </span>
  </button>
</div>
