import { Resource } from './resource';

export type Status = 'pending' | 'passed' | 'failed';

export class Identification extends Resource {
  status: Status;
  comment: Comment;
  facePhotoUrl: string;
  idPhotoUrl: string;
}
