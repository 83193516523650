import { Component, Input, ViewEncapsulation } from '@angular/core';
import * as ActionCable from '@rails/actioncable';

@Component({
  selector: 'lv-dismiss-confirm',
  styleUrls: ['dismiss-confirm.component.sass'],
  templateUrl: 'dismiss-confirm.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DismissConfirmComponent {
  @Input() channel: ActionCable.Channel;

  exit() {
    this.channel.perform('rebroadcast', { error: 'learner_dismissed' });
  }
}
