import { CameraStream } from '@services/camera';

export class Canvas {
  element: HTMLCanvasElement;
  context: CanvasRenderingContext2D;

  constructor(element?: HTMLCanvasElement, width = 640, height = 480) {
    if (element) {
      this.element = element;
    } else {
      this.element = document.createElement('canvas');
      this.width = width;
      this.height = height;
    }
  }

  set width(value: number) {
    this.element.width = value;
  }

  get width(): number {
    return this.element.width;
  }

  set height(value: number) {
    this.element.height = value;
  }

  get height(): number {
    return this.element.height;
  }

  getContext(): CanvasRenderingContext2D {
    // We only care about CanvasRenderingContext2D
    return <CanvasRenderingContext2D>this.element.getContext('2d');
  }

  toBlob(): Promise<Blob> {
    return new Promise((resolve) => {
      this.element.toBlob(resolve, 'image/png');
    });
  }

  toDataURL(): string {
    return this.element.toDataURL('image/png');
  }

  drawImageBlob(stream: CameraStream, width = 640, height = 480): Promise<Blob> {
    this.draw(stream, width, height);
    return this.toBlob();
  }

  drawImageData(stream: CameraStream, width = 640, height = 480): Promise<ImageData> {
    this.draw(stream, width, height);
    return Promise.resolve(this.context.getImageData(0, 0, width, height));
  }

  drawCanvas(stream: CameraStream, width = 640, height = 480): Promise<HTMLCanvasElement> {
    this.draw(stream, width, height);
    return Promise.resolve(this.element);
  }

  private draw(stream: CameraStream, width = 640, height = 480) {
    if (!this.context) {
      this.context = this.getContext();
    }

    this.context.drawImage(stream.video, 0, 0, width, height);
  }
}
