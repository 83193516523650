import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountIndexComponent } from '@components/account-index/account-index.component';
import { AgentIndexComponent } from '@components/agent-index/agent-index.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { EditProfileComponent } from '@components/edit-profile/edit-profile.component';
import { InfoBarComponent } from '@components/info-bar/info-bar.component';
import { LearnerIntegrationComponent } from '@components/learner-integration/learner-integration.component';
import { LearnerVerifyComponent } from '@components/learner-verify/learner-verify.component';
import { LoginFormComponent } from '@components/login-form/login-form.component';
import { SessionReviewComponent } from '@components/session-review/session-review.component';
import { SessionComponent } from '@components/session/session.component';
import { SystemCheckComponent } from '@components/system-check/system-check.component';
import { UserDetailsComponent } from '@components/user-details/user-details.component';
import { UserListComponent } from '@components/user-list/user-list.component';
import { environment } from '@environments/environment';
import { RequireAuthenticatedUser } from '@services/require-authenticated-user';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'sign_in',
        component: LoginFormComponent
      }
    ]
  },
  {
    path: '',
    component: DashboardComponent,
    canActivateChild: [RequireAuthenticatedUser],
    children: [
      {
        path: 'accounts',
        component: AccountIndexComponent,
        // TODO: Add
        // canActivate: [PunditGuard],
        data: { resource: 'Account', permission: 'create' }
      },
      {
        path: '',
        component: AgentIndexComponent,
        pathMatch: 'full'
      },
      {
        path: 'profile',
        component: EditProfileComponent
      },
      {
        path: 'sessions/:id',
        component: SessionComponent
      },
      {
        path: 'users',
        component: UserListComponent
      },
      {
        path: 'users/:id',
        component: UserDetailsComponent
      }
    ]
  },
  {
    path: 'session',
    component: LearnerIntegrationComponent,
    children: [
      {
        path: ':token/start',
        component: LearnerVerifyComponent
      },
      {
        path: ':token/running',
        component: InfoBarComponent
      },
      {
        path: ':token/review',
        component: SessionReviewComponent
      }
    ]
  },
  {
    path: 'system-check',
    component: SystemCheckComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.verboseRoutes,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
