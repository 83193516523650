<thead>
  <tr>
    <th translate>account.name</th>
    <th translate>account.token</th>
    <th>
      <button class="button" (click)="newAccount()" translate>account-index.component.new</button>
    </th>
  </tr>
</thead>
<tbody>
  <tr *ngFor="let account of accounts">
    <td>{{ account.name }}</td>
    <td>{{ account.token }}</td>
    <td>
      <button class="button is-secondary row-button" (click)="edit(account)" translate>account-index.component.edit</button>
    </td>
  </tr>
</tbody>