<div class="lv-login-box">
  <div class="lv-login-box__header">
  </div>
  <form class="lv-login-box__form" (ngSubmit)="login()">
    <div class="field">
      <div class="control has-icon">
        <input type="email" name="user[email]" [(ngModel)]="user.email" required lvI18nPlaceholder="login-form.component.email"
          class="input">
        <span class="icon">
          <i lvIcon="envelope"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <div class="control has-icon">
        <input type="password" name="user[password]" [(ngModel)]="user.password" required lvI18nPlaceholder="login-form.component.password"
          class="input">
        <span class="icon">
          <i lvIcon="asterisk"></i>
        </span>
      </div>
    </div>
    <button class="button is-success" translate>login-form.component.login</button>
  </form>
</div>

<lv-copyright></lv-copyright>
