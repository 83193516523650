<section>
  <h2 translate>edit-profile.component.title</h2>
  <form novalidate (ngSubmit)="updatePassword()" #form="ngForm">
    <div class="field">
      <input type="password" name="password" [(ngModel)]="updates.password" lvI18nPlaceholder="user.password" required class="input">
    </div>
    <div class="field">
      <input type="password" name="passwordConfirmation" [(ngModel)]="updates.passwordConfirmation" lvI18nPlaceholder="user.password-confirmation" required class="input">
    </div>
    <div class="field is-grouped is-grouped-right">
      <input type="submit" lvI18nValue="app.save" [disabled]="!valid(form)" class="button is-success">
    </div>    
  </form>
</section>
