import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DoneAuditingComponent } from '@components/done-auditing/done-auditing.component';
import { FlagPhotoComponent } from '@components/flag-photo/flag-photo.component';
import { AttendancePhoto } from '@models/attendance-photo';
import { RecordResponse } from '@models/server';
import { Session } from '@models/session';
import { Verifications } from '@models/verification';
import { Cable } from '@services/cable';
import { OverlayService } from '@services/overlay';
import { Subscription } from 'rxjs';
import * as mousetrap from 'mousetrap';
import * as ActionCable from '@rails/actioncable';

interface SessionChannelData {
  attendancePhoto?: RecordResponse;
  lastId: string;
}

@Component({
  selector: 'lv-agent-verify-attendance',
  templateUrl: './agent-verify-attendance.component.html',
  styleUrls: ['./agent-verify-attendance.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AgentVerifyAttendanceComponent implements OnInit, OnDestroy {
  @Input() session: Session;
  @Input() passed: EventEmitter<boolean>;
  @Input() failed: EventEmitter<string>;

  channel: ActionCable.Channel;
  passedSubscription: Subscription;
  failedSubscription: Subscription;
  selectedIndex = 0;
  perPage = 6;
  page = 1;

  private isFlagging = false;

  constructor(
    private verificationService: Verifications,
    private overlayService: OverlayService,
    private router: Router,
    private cable: Cable,
    private zone: NgZone
  ) {}

  get selectedPhoto(): any {
    return this.session.attendancePhotos[this.selectedIndex];
  }

  get photoNumber(): number {
    return this.selectedIndex + 1;
  }

  get photoCount(): number {
    return this.session.attendancePhotos.length;
  }

  get hasAttendancePhotos(): boolean {
    return this.photoCount > 0;
  }

  isFirstPhoto(): boolean {
    return this.selectedIndex === 0;
  }

  isLastPhoto(): boolean {
    return this.selectedIndex === this.session.attendancePhotos.length - 1;
  }

  ngOnInit() {
    this.passedSubscription = this.passed.asObservable().subscribe(() => this.passSession());

    this.failedSubscription = this.failed.asObservable().subscribe((reason) => this.failSession(reason));
    const cableParams = {
      channel: 'SessionChannel',
      token: this.session.token
    };
    if (!this.session.finished) {
      this.channel = this.cable.create(cableParams, (data: SessionChannelData) => {
        if (data.attendancePhoto) {
          const lastPhoto = this.session.attendancePhotos[this.session.attendancePhotos.length - 1];
          if (!lastPhoto || lastPhoto.id === data.lastId) {
            // We have everything, just append.
            const photo = new AttendancePhoto();
            photo.$unpack(data.attendancePhoto);
            this.session.attendancePhotos.push(photo);
          } else {
            // We're missing something, reload.
            this.session.$refresh();
          }
        }
      });
    }

    // Key binding
    mousetrap.bind('f', () => {
      this.zone.run(() => {
        if (!this.isFlagging) {
          this.flag(this.selectedPhoto);
        }
      });
    });
    mousetrap.bind('right', () => {
      this.zone.run(() => {
        if (!this.isFlagging) {
          this.next();
        }
      });
    });
    mousetrap.bind('left', () => {
      this.zone.run(() => {
        if (!this.isFlagging) {
          this.previous();
        }
      });
    });
  }

  ngOnDestroy() {
    this.passedSubscription.unsubscribe();
    this.failedSubscription.unsubscribe();
    if (this.channel) {
      this.channel.unsubscribe();
    }

    mousetrap.unbind('f');
    mousetrap.unbind('right');
    mousetrap.unbind('left');
  }

  passSession() {
    this.verificationService.pass(this.session, 'attendance').then(() => {
      this.overlayService.open(DoneAuditingComponent, {
        session: this.session,
        passOrFail: 'pass'
      });
      this.goHome();
    });
  }

  failSession(reason: string) {
    this.verificationService.fail(this.session, 'attendance', reason).then(() => {
      this.overlayService.open(DoneAuditingComponent, {
        session: this.session,
        passOrFail: 'fail'
      });
      this.goHome();
    });
  }

  next() {
    const attendancePhotos = this.session.attendancePhotos;
    this.selectedIndex = Math.min(this.selectedIndex + 1, attendancePhotos.length - 1);
    const newPage = Math.floor(this.selectedIndex / this.perPage) + 1;
    if (newPage !== this.page) {
      this.page = newPage;
    }
  }

  previous() {
    this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
    const newPage = Math.floor(this.selectedIndex / this.perPage) + 1;
    if (newPage !== this.page) {
      this.page = newPage;
    }
  }

  select(index: number) {
    this.selectedIndex = (this.page - 1) * this.perPage + index;
  }

  classFor(index: number) {
    index = index % this.perPage; // RM: occasionally got back actual index
    const pagedIndex = (this.page - 1) * this.perPage + index;

    return {
      'is-selected': this.selectedIndex === pagedIndex,
      'is-flagged': this.isFlagged(pagedIndex)
    };
  }

  isFlagged(index: number): boolean {
    const attendancePhotos = this.session.attendancePhotos;
    const photo = attendancePhotos[index];
    if (photo) {
      return photo.flagged;
    }
    return false;
  }

  flag(photo: AttendancePhoto) {
    this.isFlagging = true;

    this.overlayService.open(FlagPhotoComponent, { photo: photo }).close.subscribe(() => (this.isFlagging = false));
  }

  private goHome() {
    this.router.navigateByUrl('/');
  }
}
