import { Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DismissConfirmComponent } from '@components/dismiss-confirm/dismiss-confirm.component';
import { Learner } from '@models/learner';
import { Session, Sessions } from '@models/session';
import { Cable } from '@services/cable';
import { OverlayService } from '@services/overlay';
import * as ActionCable from '@rails/actioncable';
import * as _ from 'underscore';
interface SessionChannelData {
  next?: boolean;
  currentStep?: number;
  requestPhotos?: boolean;
  nextStep?: Steps;
}

enum Steps {
  Instructions = 1,
  SystemTest,
  UserPhoto,
  IdPhoto,
  LiveAgent,
  Biometrics,
  Ready
}
@Component({
  selector: 'lv-learner-verify',
  templateUrl: 'learner-verify.component.html',
  styleUrls: ['learner-verify.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class LearnerVerifyComponent implements OnInit, OnDestroy {
  session: Session;
  isConnected = false;
  channel: ActionCable.Channel;
  step = Steps.Instructions;
  menuOpen = false;
  nextStep: Steps;

  connectLiveAgent = new EventEmitter();
  channelReceived = new EventEmitter();

  constructor(
    private sessionService: Sessions,
    private route: ActivatedRoute,
    private router: Router,
    private cable: Cable,
    private zone: NgZone,
    private overlayService: OverlayService
  ) {}

  get learner(): Learner {
    return this.session.learner;
  }

  ngOnInit() {
    // One-time setup when we get our first connection to the cable
    const setup = _.once((params: { [key: string]: any }) => {
      this.session = this.sessionService.$findOne(`${params.token}/start`);
    });

    this.route.params.subscribe((params) => {
      this.unsubscribeCable();
      const cableParams = {
        channel: 'SessionChannel',
        token: params.token
      };
      this.channel = this.cable.subs.create(cableParams, {
        connected: () => {
          this.zone.run(() => {
            this.isConnected = true;
            setup(params);
          });
        },
        disconnected: () => this.zone.run(() => (this.isConnected = false)),
        received: (data: SessionChannelData) => {
          this.zone.run(() => {
            if (data.next) {
              if (this.step === 7) {
                this.start();
              } else {
                this.next();
              }
            }

            if (data.currentStep) {
              if (data.currentStep !== this.step) {
                this.step = data.currentStep;
              }
            }

            if (data.nextStep) {
              this.nextStep = data.nextStep;
            }

            if (data.requestPhotos) {
              this.channel.perform('rebroadcast', {
                freshFacePhoto: this.session.facePhoto.payload,
                freshIdPhoto: this.session.idPhoto.payload
              });
            }
          });
        }
      });
    });

    this.connectLiveAgent.asObservable().subscribe(() => this.requestLiveAgent());
  }

  ngOnDestroy() {
    this.unsubscribeCable();
  }

  back() {
    this.step -= 1;

    switch (this.step) {
      case Steps.UserPhoto:
      case Steps.IdPhoto:
        if (!this.session.requiresIdentification) {
          this.back();
          return;
        }
        break;
      case Steps.LiveAgent:
        if (!this.session.liveAgent) {
          // Skip this step if it's not enabled.
          this.back();
          return;
        }
        break;
      case Steps.Biometrics:
        if (!this.session.biometrics) {
          // Skip this step if it's not enabled.
          this.back();
          return;
        }
        break;
    }

    this.channel.perform('rebroadcast', { currentStep: this.step });
  }

  next() {
    if (this.nextStep) {
      this.channel.perform('rebroadcast', { currentStep: this.nextStep });
      this.nextStep = undefined;
      return;
    }

    this.step += 1;

    switch (this.step) {
      case Steps.UserPhoto:
      case Steps.IdPhoto:
        if (!this.session.requiresIdentification) {
          this.next();
          return;
        }
        break;
      case Steps.LiveAgent:
        if (!this.session.liveAgent) {
          // Skip this step if it's not enabled.
          this.next();
          return;
        }
        break;
      case Steps.Biometrics:
        if (!this.session.biometrics) {
          // Skip this step if it's not enabled.
          this.next();
          return;
        }
        break;
    }

    this.channel.perform('rebroadcast', { currentStep: this.step });
  }

  start() {
    this.sessionService.start(this.session).then(
      (session) => this.router.navigateByUrl(`/session/${session.token}/running`),
      (error) => console.error('failed starting session', error)
    );
  }

  restart() {
    this.step = Steps.Instructions;
    this.learner.biometricsTrained = false;
    this.session.requiresIdentification = true;
    this.channel.perform('rebroadcast', { currentStep: this.step });
  }

  classFor(step: Steps) {
    return {
      'is-active': step === this.step,
      'is-complete': step < this.step
    };
  }

  toggleMenu(value: boolean) {
    this.menuOpen = value;
  }

  close() {
    this.channel.perform('rebroadcast', { error: this.session.$error });
  }

  confirmDismiss() {
    this.overlayService.open(DismissConfirmComponent, {
      channel: this.channel
    });
  }

  requestLiveAgent() {
    this.channel.perform('request_live_agent', {});

    this.session.tokbox.paired.subscribe((paired) => {
      if (paired) {
        this.channel.perform('rebroadcast', {
          freshFacePhoto: this.session.facePhoto.payload,
          freshIdPhoto: this.session.idPhoto.payload
        });
      }
    });
  }

  private unsubscribeCable() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  }
}
