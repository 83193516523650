import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WebhookFormComponent } from '@components/webhook-form/webhook-form.component';
import { Account } from '@models/account';
import { Webhook, Webhooks } from '@models/webhook';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-webhooks',
  templateUrl: 'webhooks.component.html',
  styleUrls: ['webhooks.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class WebhooksComponent {
  @Input() account: Account;

  constructor(private overlayService: OverlayService, private webhookService: Webhooks) {}

  edit(webhook: Webhook) {
    this.overlayService.open(WebhookFormComponent, { webhook: webhook });
  }

  destroy(webhook: Webhook) {
    this.webhookService.$destroy(webhook).then(() => this.account.webhooks.$removeResource(webhook));
  }

  newWebhook() {
    const webhook = this.account.webhooks.$build({ event: 'pass_fail', accountId: this.account.id });
    this.overlayService.open(WebhookFormComponent, { webhook: webhook });
  }
}
