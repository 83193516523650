import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Webhook, Webhooks } from '@models/webhook';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-webhook-form',
  templateUrl: 'webhook-form.component.html',
  styleUrls: ['webhook-form.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class WebhookFormComponent {
  @Input() webhook: Webhook;

  constructor(private webhookService: Webhooks, private overlayService: OverlayService) {}

  save() {
    this.webhookService.$save(this.webhook).then(
      () => this.overlayService.closeTopOverlay(),
      (webhook: Webhook) => console.error(webhook.$error)
    );
  }

  get title(): string {
    return `webhook-form.component.title.${this.webhook.newRecord()}`;
  }
}
