import { HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { TokenService } from '@services/token-service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

import { Deserialize } from './decorators';
import { Error } from './error';
import { CollectionResponse, Record, RecordResponse, Relationship, RelationshipData, Relationships } from './server';

type KeyMapper = (s: string) => string;

const deepMapKeys = require('deep-map-keys');
const camelize: KeyMapper = require('underscore.string/camelize');
const underscore: KeyMapper = require('underscore.string/underscored');

export class Pagination {
  currentPage: number;
  perPage: number;
  totalItems: number;
  totalPages: number;

  constructor(attributes: Object) {
    Object.assign(this, deepMapKeys(attributes, camelize));
  }
}

export abstract class Resource {
  @Deserialize((v) => v && new Date(v))
  createdAt: Date;

  @Deserialize((v) => v && new Date(v))
  updatedAt: Date;

  // Semi-private internal stuff
  $collection: ResourceCollection<this>;
  $error: Error;
  $promise: Promise<this>;
  $service: ResourceService<this>;

  // Actual attributes
  id: string;

  private _$resolved = false;
  private $resolve;
  private $reject;

  constructor(attributes?: Object) {
    this.$assign(attributes);
    this.$error = null;
  }

  asPromise(): Promise<this> {
    if (!this.$promise) {
      this.$promise = new Promise<this>((resolve, reject) => {
        this.$resolve = resolve;
        this.$reject = reject;
      });
    }
    return this.$promise;
  }

  newRecord(): boolean {
    return !this.id;
  }

  json(): any {
    return Object.keys(this).reduce((obj, key) => {
      // Ignore our internal $ variables.
      if (key.startsWith('$')) {
        return obj;
      }

      // Ignore _ things.
      if (key.startsWith('_')) {
        return obj;
      }

      // We don't send the id.
      if (key === 'id') {
        return obj;
      }

      // Just don't include these.
      if (key === 'createdAt' || key === 'updatedAt') {
        return obj;
      }

      obj[underscore(key)] = this[key];
      return obj;
    }, {});
  }

  $unpack(resp: RecordResponse) {
    this.$error = null;

    const data = resp.data;
    const included = resp.included;

    this.id = data.id;
    this.$assign(data.attributes);

    if (data.relationships && included) {
      this.$unpackRelationships(data.relationships, included);
    }
  }

  $refresh() {
    this.$service.$refresh(this);
  }

  $assign(attributes: Object) {
    Object.assign(this, deepMapKeys(attributes, camelize));
  }

  set $resolved(value: boolean) {
    this._$resolved = value;
    if (!value) {
      return;
    }
    if (this.$promise) {
      if (this.$error) {
        this.$reject(this);
      } else {
        this.$resolve(this);
      }
    }
  }

  get $resolved(): boolean {
    return this._$resolved;
  }

  private $unpackRelationships(relationships: Relationships, included: [Record]) {
    Object.entries(relationships).forEach(([name, rel]: [string, Relationship]) => {
      name = camelize(name);
      if (_.isArray(rel.data)) {
        // This is gross. jsonapi is stupid.
        const relAttrs = included.filter((value) =>
          (<RelationshipData[]>rel.data).some((data) => value.id === data.id && value.type === data.type)
        );
        for (const item of relAttrs) {
          item.included = included; // Pass this down.
        }
        this[name] = relAttrs;
      } else {
        const relAttrs = rel.data ? _.find(included, rel.data) : null;
        if (relAttrs) {
          this[name] = relAttrs;
        }
      }
    });
  }
}

export class ResourceCollection<T extends Resource> {
  $error: Error;
  $resolved = false;

  pagination?: Pagination;

  private $collection: T[];
  private $collectionChanged = new EventEmitter<this>();

  constructor(
    protected ctor: any,
    private fn?: (paramsOverride?: HttpParams) => Observable<HttpResponse<CollectionResponse>>,
    private service?: ResourceService<T>
  ) {
    this.$collection = [];
    this.$refresh();
  }

  static fromArray(ctor: any, records: any[]) {
    const rc = new ResourceCollection(ctor);
    for (const record of records) {
      rc.$add(record);
    }
    return rc;
  }

  $refresh(query?: Object) {
    // Setup new params
    let refreshParams: HttpParams;
    if (query) {
      refreshParams = new HttpParams();
      for (const key in query) {
        refreshParams = refreshParams.set(key, query[key]);
      }
    }

    this.$resolved = false;
    this.$error = null;
    if (this.fn) {
      this.fn(refreshParams)
        .pipe(finalize(() => (this.$resolved = true)))
        .subscribe(
          (res) => this.$unpack(res.body),
          (err) => (this.$error = new Error(err.error))
        );
    } else {
      this.$resolved = true;
    }
  }

  // Build a resource tied to this collection.
  $build(attributes?: any): T {
    const resource = new this.ctor(attributes);
    resource.$collection = this;
    this.$collection.push(resource);
    this.$collectionChanged.emit(this);
    return resource;
  }

  $add(resource: T) {
    resource.$collection = this;
    this.$collection.push(resource);
    this.$collectionChanged.emit(this);
  }

  $at(index: number) {
    return this.$collection[index];
  }

  $indexOf(resource: T) {
    return this.$collection.indexOf(resource);
  }

  $remove(id: number | string) {
    const resource = this.$findWhere({ id: `${id}` });
    this.$removeResource(resource);
  }

  $removeResource(resource: T) {
    resource.$collection = null;
    const index = this.$collection.indexOf(resource);
    if (index >= 0) {
      this.$collection.splice(index, 1);
    }
  }

  $asObservable(): Observable<this> {
    return this.$collectionChanged.asObservable();
  }

  $sortBy(attribute: string) {
    let multiplier = 1;
    if (attribute[0] === '-') {
      multiplier = -1;
    }
    this.$collection.sort((a: T, b: T) => {
      if (a[attribute] < b[attribute]) {
        return -1 * multiplier;
      }

      if (a[attribute] > b[attribute]) {
        return 1 * multiplier;
      }

      return 0;
    });
  }

  $toArray(): T[] {
    const out: T[] = [];
    for (const record of this) {
      out.push(record);
    }
    return out;
  }

  filter(callback, thisArg = null): T[] {
    return this.$toArray().filter(callback, thisArg);
  }

  $where<U extends {}>(properties: U): T[] {
    return _.where(this.$toArray(), properties);
  }

  $findWhere<U extends {}>(properties: U): T | undefined {
    return _.findWhere(this.$toArray(), properties);
  }

  $findById(id: number | string): T | undefined {
    return this.$findWhere({ id: `${id}` });
  }

  get length(): number {
    return _.where<any>(this.$collection, { $resolved: true }).length;
  }

  *[Symbol.iterator]() {
    for (const record of this.$collection) {
      if (record.$resolved) {
        yield record;
      }
    }
  }

  private $unpack(resp: CollectionResponse) {
    if (resp.meta && resp.meta.pagination) {
      this.pagination = new Pagination(resp.meta.pagination);
    }
    this.$collection = resp.data.map((record: Record) => {
      const res = new this.ctor({ $resolved: true });
      res.$service = this.service;
      res.$unpack({ data: record, included: resp.included });
      res.$collection = this;
      return res;
    });
    this.$collectionChanged.emit(this);
  }
}

export abstract class ResourceService<T extends Resource> {
  ctor: any;

  protected base: string;

  constructor(private tokenService: TokenService) {}

  $search(query?: Object): ResourceCollection<T> {
    let params = new HttpParams();
    if (query) {
      for (const key in query) {
        params = params.set(key, query[key]);
      }
    }
    return new ResourceCollection<T>(
      this.ctor,
      (paramsOverride?: HttpParams) => this.http.get<CollectionResponse>(this.base, paramsOverride || params),
      this
    );
  }

  $refresh(resource: T): T {
    resource.$resolved = false;
    resource.$error = null;
    this.http
      .get<RecordResponse>(`${this.base}/${resource.id}`)
      .pipe(finalize(() => (resource.$resolved = true)))
      .subscribe(
        (res) => resource.$unpack(res.body),
        (err) => (resource.$error = new Error(err.error))
      );
    return resource;
  }

  $find(id: number): T {
    return this.$findOne(id);
  }

  $findOne(path: any): T {
    const resource = <Resource>new this.ctor();
    resource.$service = this;
    this.http
      .get<RecordResponse>(`${this.base}/${path}`)
      .pipe(finalize(() => (resource.$resolved = true)))
      .subscribe(
        (res) => resource.$unpack(res.body),
        (err) => (resource.$error = new Error(err.error))
      );
    return <T>resource;
  }

  $findAll(path: any, query?: Object): ResourceCollection<T> {
    let params = new HttpParams();
    if (query) {
      for (const key in query) {
        params = params.set(key, query[key]);
      }
    }
    return new ResourceCollection<T>(
      this.ctor,
      (paramsOverride?: HttpParams) =>
        this.http.get<CollectionResponse>(`${this.base}/${path}`, paramsOverride || params),
      this
    );
  }

  $create(attributes: any): T {
    const resource = <Resource>new this.ctor(attributes);
    resource.$service = this;
    this.http
      .post<RecordResponse>(`${this.base}`, attributes)
      .pipe(finalize(() => (resource.$resolved = true)))
      .subscribe(
        (res) => resource.$unpack(res.body),
        (err) => (resource.$error = new Error(err.error))
      );
    return <T>resource;
  }

  $save(resource: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const body = resource.json();
      const newRecord = resource.newRecord();
      const resp = newRecord
        ? this.http.post<RecordResponse>(`${this.base}`, body)
        : this.http.patch<RecordResponse>(`${this.base}/${resource.id}`, body);
      resp.pipe(finalize(() => (resource.$resolved = true))).subscribe(
        (res) => {
          resource.$unpack(res.body);
          resolve(resource);
        },
        (err) => {
          resource.$error = new Error(err.error);
          reject(resource);
        }
      );
    });
  }

  $destroy(resource: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.http
        .delete(`${this.base}/${resource.id}`)
        .pipe(finalize(() => (resource.$resolved = true)))
        .subscribe(
          () => resolve(resource),
          (err) => {
            resource.$error = new Error(err.error);
            reject(resource);
          }
        );
    });
  }

  $post(resource: T, path: string, body?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.http.post<RecordResponse>(`${this.base}/${resource.id}/${path}`, body).subscribe(
        (res) => {
          resource.$unpack(res.body);
          resolve(resource);
        },
        (err) => {
          resource.$error = new Error(err.error);
          reject(resource);
        }
      );
    });
  }

  protected get http() {
    return this.tokenService;
  }
}

export function HasOne(ctor) {
  return function (target: any, key: string): any {
    const getter = function () {
      return this[`$$${key}`];
    };

    const setter = function (value: any) {
      if (value) {
        if (_.isArray(value)) {
          value = value[0];
        }
        // If we're setting this to something not already the required class
        if (value.constructor !== ctor) {
          // and it's from the server
          if ('id' in value && 'attributes' in value) {
            const record = <Record>value;
            value = Object.assign({ id: record.id, $resolved: true }, record.attributes);
          }
          value = new ctor(value);
        }
      }
      this[`$$${key}`] = value;
    };

    if (delete target[key]) {
      Object.defineProperty(target, key, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}

// Do you have a plain array of the thing? Thing[] ? Don't useCollection: @HasMany(Thing)
// Do you have a ResourceCollection<Thing>? Use useCollection: @HasMany(Thing, true)
// The collection has some benefits but I would probably just use the array unless you need the features.
export function HasMany(ctor: any, useCollection = false) {
  return function (target: any, key: string): any {
    const getter = function () {
      if (useCollection && !this[`$$${key}`]) {
        this[`$$${key}`] = ResourceCollection.fromArray(ctor, []);
      }
      return this[`$$${key}`];
    };

    const setter = function (values: any[]) {
      // If we're setting this to something not already the required class
      const records = values.map((value) => {
        if (value.constructor === ctor) {
          // They are already records.
          return value; // Just return the record.
        } else {
          const resource: Resource = new ctor({ $resolved: true });
          // and it's from the server
          if ('id' in value && 'attributes' in value) {
            const record = <Record>value;
            resource.$unpack({ data: record, included: record.included });
          }
          return resource;
        }
      });

      if (useCollection) {
        this[`$$${key}`] = ResourceCollection.fromArray(ctor, records);
      } else {
        this[`$$${key}`] = records;
      }
    };

    if (delete target[key]) {
      Object.defineProperty(target, key, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}
