import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SignInData, TokenService } from '@services/token-service';

@Component({
  selector: 'lv-login',
  templateUrl: 'login-form.component.html',
  styleUrls: ['login-form.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class LoginFormComponent {
  user: SignInData;

  constructor(private tokenService: TokenService, private router: Router) {
    this.user = {
      email: '',
      password: ''
    };
  }

  login() {
    this.tokenService.signIn(this.user).subscribe(
      () => {
        let path = this.tokenService.redirectTo() || '/';
        if (path.includes('auth')) {
          path = '/';
        }
        this.router.navigateByUrl(path);
      },
      (err) => console.log(err)
    );
  }
}
