import { Input, Directive } from '@angular/core';
import { Learner } from '@models/learner';
import { Session } from '@models/session';
import * as ActionCable from '@rails/actioncable';

@Directive()
export abstract class AbstractBiometricsDirective {
  // eslint-disable-next-line
  @Input('verify') _verify: boolean;
  @Input() learner: Learner;
  @Input() session: Session;

  abstract reset();
  abstract verify(channel: ActionCable.Channel, forceTraining: boolean);
  abstract ready(): boolean;

  get registerOrAuth(): string {
    return this.learner.biometricsTrained ? 'authorize' : 'register';
  }
}
