import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';

@Component({
  selector: 'lv-attendance-timeline',
  templateUrl: 'attendance-timeline.component.html',
  styleUrls: ['attendance-timeline.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceTimelineComponent {
  @Input() sessions: Session[];
}
