<div class="body-header">
  <h3 translate>verify-photo.component.title</h3>
</div>
<div class="body-content">
  <div class="content" [innerHTML]="'verify-photo.component.content' | translate"></div>
  <lv-details-panel label="app.help" [open]="true">
    <p translate>verify-photo.component.photo-info</p>
    <br />
    <img src="assets/facecapture.svg" />
  </lv-details-panel>
  <div class="photo-wrapper">
    <div class="svg-wrapper" *ngIf="!photo">
      <lv-camera-feed
        class="mirror"
        filename="face.png"
        [keepRunning]="true"
        (capture)="capture($event)"
      ></lv-camera-feed>
      <img class="svg-item" src="assets/photo-oval.svg" />
    </div>
    <img id="the-photo" *ngIf="photo" [src]="photoURL" />
    <button *ngIf="photo" class="button is-secondary is-fullwidth" (click)="reset()">
      <span class="icon">
        <i lvIcon="refresh"></i>
      </span>
      <span translate>verify-photo.component.try-again</span>
    </button>
    <div *ngIf="saving" class="photo-uploading" translate>app.uploading</div>
  </div>
</div>
<div class="body-footer">
  <button
    *ngIf="showNextButton"
    [disabled]="nextDisabled"
    class="button is-primary is-fullwidth next-button"
    (click)="done()"
  >
    <span translate>app.next</span>
    <span class="icon">
      <i lvIcon="long-arrow-right"></i>
    </span>
  </button>
</div>
