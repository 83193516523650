import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceCollection } from '@models/resource';
import { Session, Sessions } from '@models/session';

@Component({
  selector: 'lv-session-review',
  templateUrl: 'session-review.component.html',
  styleUrls: ['session-review.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SessionReviewComponent implements OnInit {
  sessions: ResourceCollection<Session>;
  activeSession: Session;

  constructor(private sessionService: Sessions, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.sessions = this.sessionService.$findAll(`${params.token}/review`);
      this.sessions.$asObservable().subscribe((sessions) => {
        sessions.$sortBy('startedAt');
        this.activeSession = this.sessions.$at(0);
      });
    });
  }

  @HostBinding('class')
  get status(): string {
    for (const session of this.sessions) {
      if (session.status === 'failed' || session.status === 'pending') {
        return 'is-' + session.status;
      }
    }

    return 'is-success';
  }
}
