import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AttendancePhoto } from '@models/attendance-photo';
import { PhotoComments } from '@models/photo-comment';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-flag-photo',
  templateUrl: 'flag-photo.component.html',
  styleUrls: ['flag-photo.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class FlagPhotoComponent {
  @Input() photo: AttendancePhoto;
  comment: string;

  constructor(private photoCommentService: PhotoComments, private overlayService: OverlayService) {}

  submit() {
    this.photoCommentService
      .$create({
        attendance_photo_id: this.photo.id,
        session_id: this.photo.sessionId,
        comment: this.comment
      })
      .asPromise()
      .then(() => {
        this.overlayService.closeTopOverlay();
        this.photo.flagged = true;
      });
  }
}
