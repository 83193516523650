import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';

@Component({
  selector: 'lv-walk-step-complete',
  templateUrl: 'walk-step-complete.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WalkStepCompleteComponent {
  @Input() session: Session;

  get displayAutomatedAttendanceOptions(): boolean {
    const serviceEnabled = this.session.services.includes('automated_attendance');
    const optionsEnabled = this.session.serviceOptions.automatedAttendance.anythingEnabled;
    return serviceEnabled && optionsEnabled;
  }
}
