import { PhotoComment } from './photo-comment';
import { HasMany, Resource } from './resource';

export class AttendancePhoto extends Resource {
  @HasMany(PhotoComment)
  photoComments: PhotoComment[];

  sessionId: number;
  photoUrl: string;
  flagged: boolean;
  anomalyId: string;
}
