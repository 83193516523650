import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Identification } from '@models/identification';
import { Session } from '@models/session';
import { Verification } from '@models/verification';

@Component({
  selector: 'lv-session-review-id',
  templateUrl: 'session-review-id.component.html',
  styleUrls: ['session-review-id.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SessionReviewIdComponent {
  @Input() session: Session;

  get identification(): Identification {
    return this.session.activeIdentification;
  }

  get idVerifications(): Verification[] {
    return this.session.verifications.filter(
      (verification) => verification.service === 'identification' && verification.status === 'failed'
    );
  }

  verificationReasons(): string[] {
    const reasons = [];
    this.idVerifications.forEach((verification) => {
      reasons.push(verification.reason.replace(/\n/g, '<br>'));
    });

    return reasons;
  }
}
