import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PDFDialogComponent } from '@components/pdf-dialog/pdf-dialog.component';
import { Learner } from '@models/learner';
import { Session } from '@models/session';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-verify-instructions',
  styleUrls: ['verify-instructions.component.sass'],
  templateUrl: 'verify-instructions.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyInstructionsComponent {
  @Input() session: Session;
  @Input() learner: Learner;
  @Output() next = new EventEmitter();

  hasID = false;
  hasPP = false;

  constructor(private overlayService: OverlayService) {}

  showPolicy(event: MouseEvent) {
    event.preventDefault();

    this.overlayService.open(PDFDialogComponent, {
      url: 'https://s3.ca-central-1.amazonaws.com/privacy-yardstick/lv-privacy-policy.pdf',
      title: 'verify-instructions.component.privacy-policy'
    });
  }

  showTerms(event: MouseEvent) {
    event.preventDefault();

    this.overlayService.open(PDFDialogComponent, {
      url: 'https://s3.ca-central-1.amazonaws.com/privacy-yardstick/lv-terms-use.pdf',
      title: 'verify-instructions.component.terms-use'
    });
  }

  done() {
    this.next.emit();
  }
}
