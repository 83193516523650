import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Event {
  channel: string;
  data: any;
}

@Injectable({ providedIn: 'root' })
export class EventService {
  private event$ = new Subject<Event>();

  public publish(channel: string, data: any = null): void {
    this.event$.next({ channel: channel, data: data });
  }

  public of<T>(channel: string): Observable<T> {
    return this.event$.pipe(
      filter((m) => m.channel === channel),
      map((m) => m.data)
    );
  }
}
