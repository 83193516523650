<div *ngIf="hasAttendancePhotos">
  <div class="carousel-selected">
    <img [src]="selectedPhoto?.photoUrl" />
    <button selected-flag class="button is-failure" (click)="flag(selectedPhoto)" [disabled]="isFlagged(selectedIndex)">
      <span class="icon">
        <i lvIcon="flag"></i>
      </span>
      <span translate>agent-verify-attendance.component.flag</span>
    </button>
    <button selected-previous class="button is-primary is-large" (click)="previous()" [disabled]="isFirstPhoto()">
      <span class="icon is-large">
        <i lvIcon="caret-left"></i>
      </span>
    </button>
    <button selected-next class="button is-primary is-large" (click)="next()" [disabled]="isLastPhoto()">
      <span class="icon is-large">
        <i lvIcon="caret-right"></i>
      </span>
    </button>
    <div selected-index>{{ photoNumber }} of {{ photoCount }}</div>
    <div selected-timestamp>{{ selectedPhoto?.createdAt }}</div>
  </div>
  <div class="carousel-thumbnails">
    <div class="carousel-thumbnail" [ngClass]="classFor(i)" *ngFor="let photo of session.attendancePhotos | paginate:{ itemsPerPage: perPage, currentPage: page }; let i = index">
      <img [src]="photo.photoUrl" [alt]="photo.createdAt | date:'medium'" (click)="select(i)" />
    </div>
  </div>
  <pagination-controls class="carousel-pagination" (pageChange)="page = $event" (pageBoundsCorrection)="page = $event"></pagination-controls>
</div>
