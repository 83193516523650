import { Injectable } from '@angular/core';
import { Canvas } from '@models/canvas';

export enum CameraState {
  Uninitialized,
  Activated,
  Deactivated
}

export class CameraStream {
  private stream: MediaStream;

  constructor(public camera: Camera, public video: HTMLVideoElement) {}

  static cameraDimensions(): Promise<{ width: number; height: number }> {
    const constraints = {
      audio: false,
      video: {
        facingMode: 'user'
      }
    };
    let streamToStop: MediaStream;
    const video = document.createElement('video');
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        video.srcObject = streamToStop = stream;
        return new Promise((resolve) => (video.onloadedmetadata = resolve));
      })
      .then(() => {
        const dimensions = {
          width: video.videoWidth,
          height: video.videoHeight
        };
        video.pause();
        for (const track of streamToStop.getTracks()) {
          track.stop();
        }
        video.srcObject = null;
        return dimensions;
      });
  }

  start(stream: MediaStream): Promise<void> {
    this.stream = stream;
    if ('srcObject' in this.video) {
      this.video.srcObject = stream;
    }

    return this.video.play();
  }

  stop() {
    this.video.pause();
    if ('srcObject' in this.video) {
      this.video.srcObject = null;
    } else {
      (<HTMLVideoElement>this.video).src = null;
    }

    if (!this.stream) {
      return;
    }

    for (const track of this.stream.getTracks()) {
      track.stop();
    }
  }
}

export interface Webcam {
  _capture(options?: any): ImageData;
}

@Injectable({ providedIn: 'root' })
export abstract class Camera {
  abstract state: CameraState;
  abstract activate(facing?: string): Promise<MediaStream>;
  abstract snapBlob(stream: CameraStream): Promise<Blob>;
  abstract snapData(stream: CameraStream): Promise<ImageData>;
  abstract snapCanvas(stream: CameraStream): Promise<HTMLCanvasElement>;
  abstract streamTo(container: HTMLElement, playingCallback?: () => void, facing?: string): CameraStream;
  abstract get height(): number;
  abstract get width(): number;
}

@Injectable({ providedIn: 'root' })
export class WebRTCCamera extends Camera {
  public state = CameraState.Uninitialized;
  private cameraWidth = 640;
  private cameraHeight = 480;

  private video: HTMLVideoElement;

  get height(): number {
    return this.cameraHeight;
  }

  get width(): number {
    return this.cameraWidth;
  }

  activate(facingMode = 'user'): Promise<MediaStream> {
    const constraints = {
      audio: false,
      video: {
        facingMode
      }
    };

    if (this.useMediaDevices) {
      return navigator.mediaDevices.getUserMedia(constraints);
    } else {
      const error = 'getUserMedia not supported';
      console.error(error);
      this.state = CameraState.Deactivated;

      return Promise.reject(error);
    }
  }

  streamTo(container: HTMLElement, playingCallback?: () => void, facing = 'user'): CameraStream {
    const video = this.initVideo(container);
    const cameraStream = new CameraStream(this, video);
    this.activate(facing).then(
      (stream: MediaStream) => {
        cameraStream.start(stream).then(() => {
          this.state = CameraState.Activated;
          playingCallback?.();
        });
      },
      (err) => {
        console.error('failed activating stream', err);
        this.state = CameraState.Deactivated;
      }
    );
    return cameraStream;
  }

  snapBlob(stream: CameraStream): Promise<Blob> {
    const canvas = new Canvas(null, this.cameraWidth, this.cameraHeight);
    return canvas.drawImageBlob(stream, this.cameraWidth, this.cameraHeight);
  }

  snapData(stream: CameraStream): Promise<ImageData> {
    const canvas = new Canvas(null, this.cameraWidth, this.cameraHeight);
    return canvas.drawImageData(stream, this.cameraWidth, this.cameraHeight);
  }

  snapCanvas(stream: CameraStream): Promise<HTMLCanvasElement> {
    const canvas = new Canvas(null, this.cameraWidth, this.cameraHeight);
    return canvas.drawCanvas(stream, this.cameraWidth, this.cameraHeight);
  }

  private get useMediaDevices(): boolean {
    return 'mediaDevices' in navigator && typeof navigator.mediaDevices.getUserMedia === 'function';
  }

  private initVideo(container) {
    if (this.video) {
      this.video.remove();
    }

    this.video = document.createElement('video');
    this.video.muted = this.video.autoplay = true;
    this.video.setAttribute('playsinline', 'true'); // for mobile safari
    this.video.onloadedmetadata = () => {
      const { videoWidth, videoHeight } = this.video;
      let width = videoWidth;
      let height = videoHeight;
      // Mobile Safari incorrectly reports camera width/height, lets enforce the correct aspect-ratio
      if (this.isMobileSafari(navigator.userAgent)) {
        width = Math.min(videoWidth, videoHeight);
        height = Math.max(videoWidth, videoHeight);
      }
      this.cameraWidth = width;
      this.cameraHeight = height;
      this.video.onloadedmetadata = null;

      container.appendChild(this.video);
    };
    return this.video;
  }

  private isMobileSafari(userAgent) {
    return /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent);
  }
}
