import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'lv-pdf-dialog',
  styleUrls: ['pdf-dialog.component.sass'],
  templateUrl: 'pdf-dialog.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PDFDialogComponent implements OnInit {
  @Input() title: string;
  @Input() url: string;

  safeURL: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://docs.google.com/viewer?url=${this.url}&embedded=true`
    );
  }
}
