import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class NewrelicErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  handleError(error: any) {
    super.handleError(error);

    if ('newrelic' in window) {
      newrelic.noticeError(error);
    }
  }
}
