import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { SessionFailedReasonComponent } from '@components/session-failed-reason/session-failed-reason.component';
import { Identification } from '@models/identification';
import { Session } from '@models/session';
import { OverlayService } from '@services/overlay';

@Component({
  selector: 'lv-session-details',
  templateUrl: 'session-details.component.html',
  styleUrls: ['session-details.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SessionDetailsComponent {
  @Input() session: Session;
  @Input() passed: EventEmitter<boolean>;
  @Input() failed: EventEmitter<string>;

  constructor(private overlayService: OverlayService) {}

  get learner() {
    return this.session.learner;
  }

  get identification(): Identification {
    return this.session.activeIdentification;
  }

  get tokboxEnabled(): boolean {
    return this.session.tokbox && !this.session.finished;
  }

  verifyLabel(): string {
    return this.session.idVerified ? 'attendance' : 'id-photo';
  }

  pass() {
    this.passed.emit(true);
  }

  fail() {
    this.overlayService.open(SessionFailedReasonComponent, {
      submitted: this.failed,
      session: this.session
    });
  }

  passEnabled(): boolean {
    return this.session.canPass();
  }

  failDisabled(): boolean {
    return this.session.failed || this.session.passed;
  }
}
