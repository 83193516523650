export class Error {
  status: number;
  errors: string[];
  error: string;
  exception: string;

  constructor(err) {
    Object.assign(this, err);
  }

  get message() {
    if (this.error) {
      return this.error;
    }
    return this.errors.join(', ');
  }

  get biometricsFailed(): boolean {
    return this.error === 'biometrics_failed';
  }
}
