import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Resource } from '@models/resource';
import { Users } from '@models/user';
import { Pundit } from '@services/pundit';

@Directive({
  selector: '[lvPermit]'
})
export class PermitDirective {
  constructor(
    private userService: Users,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set lvPermit(tuple: [string, string | Resource]) {
    const [permission, resource] = tuple;
    this.userService
      .currentUser()
      .asPromise()
      .then((user) => {
        const policy = Pundit.policy(user, resource);
        if (policy[permission]()) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }
}
