import { Injectable } from '@angular/core';
import { TokenService } from '@services/token-service';

import { Resource, ResourceService } from './resource';
import { Session } from './session';

export type Status = 'passed' | 'failed';
export type VerificationService = 'identification' | 'attendance';

export class Verification extends Resource {
  service: VerificationService;
  status: Status;
  userId: number;
  sessionId: number;
  reason: string;

  get failed(): boolean {
    return this.status === 'failed';
  }
}

@Injectable({ providedIn: 'root' })
export class Verifications extends ResourceService<Verification> {
  constructor(tokenService: TokenService) {
    super(tokenService);
    this.base = 'verifications';
    this.ctor = Verification;
  }

  pass(session: Session, service: VerificationService): Promise<Session> {
    const attributes = {
      session_id: session.id,
      status: 'passed',
      service: service
    };
    return this.$create(attributes)
      .asPromise()
      .then(() => {
        session.$refresh();
        return session.asPromise();
      });
  }

  fail(session: Session, service: VerificationService, reason: string): Promise<Session> {
    const attributes = {
      session_id: session.id,
      status: 'failed',
      service: service,
      reason: reason
    };
    return this.$create(attributes)
      .asPromise()
      .then(() => {
        session.$refresh();
        return session.asPromise();
      });
  }
}
