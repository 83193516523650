<section lvCloseOverlay withKeyboard="esc" *ngIf="sessions.$resolved">
  <header>
    <h2 translate>session-review.component.title</h2>
    <img src="assets/lv-brand.svg" alt="LearnerVerified" />
  </header>
  <div class="body content" *ngIf="!sessions.length">
    <p translate>session-review.component.nothing-yet</p>
  </div>
  <div class="body" *ngIf="sessions.length">
    <lv-session-review-id [session]="activeSession"></lv-session-review-id>
    <lv-session-review-attendance [sessions]="sessions"></lv-session-review-attendance>
  </div>
</section>
