import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResourceCollection } from '@models/resource';
import { User, Users } from '@models/user';
import { FileSaverService } from 'ngx-filesaver';
import * as XLSX from 'xlsx';

@Component({
  selector: 'lv-user-list',
  templateUrl: 'user-list.component.html',
  styleUrls: ['user-list.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit {
  @ViewChild('data', { static: true, read: ElementRef }) dataTable: ElementRef;
  users: ResourceCollection<User>;

  userFilter = { name: '', email: '' };

  constructor(private userService: Users, private saver: FileSaverService) {}

  ngOnInit() {
    this.users = this.userService.$search();
  }

  export() {
    const book = XLSX.utils.table_to_book(this.dataTable.nativeElement);
    const data = XLSX.write(book, { bookType: 'xlsx', type: 'array' });
    this.saver.save(new Blob([data]), 'LearnerVerified-Agents.xlsx');
  }
}
