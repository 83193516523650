import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SystemCheckComponent } from '@components/system-check/system-check.component';
import { Session } from '@models/session';
import { FaceTracker } from '@services/face-tracker';

@Component({
  selector: 'lv-verify-system',
  styleUrls: ['verify-system.component.sass'],
  templateUrl: 'verify-system.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifySystemComponent {
  @ViewChild(SystemCheckComponent, { static: true }) systemCheck: SystemCheckComponent;
  @Input() session: Session;
  @Output() next = new EventEmitter();

  webcamOk = false;
  websocketsOk = false;

  constructor(private faceTracker: FaceTracker) {}

  done() {
    this.faceTracker.initialize();
    this.next.emit();
  }
}
