import { Injectable } from '@angular/core';
import { TokenService } from '@services/token-service';

import { Resource, ResourceService } from './resource';

export class User extends Resource {
  name: string;
  email: string;
}

@Injectable({ providedIn: 'root' })
export class Users extends ResourceService<User> {
  private _currentUser: User;

  constructor(tokenService: TokenService) {
    super(tokenService);
    this.base = 'users';
    this.ctor = User;
  }

  currentUser(): User {
    if (this._currentUser) {
      return this._currentUser;
    }
    this._currentUser = this.$findOne('current');
    return this._currentUser;
  }
}
