import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceCollection } from '@models/resource';
import { Session, Sessions } from '@models/session';
import { TranslateService } from '@ngx-translate/core';
import { Cable } from '@services/cable';
import { Notifier } from '@services/notifier';
import { Subscription } from 'rxjs';
import * as ActionCable from '@rails/actioncable';
import { TokenService } from '@services/token-service';

interface ClaimsListChannelData {
  started?: boolean;
  claimed?: boolean;
  finished?: boolean;
  id?: number;
  claimedBy?: number;
}

@Component({
  selector: 'lv-agent-index',
  templateUrl: 'agent-index.component.html',
  styleUrls: ['agent-index.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AgentIndexComponent implements OnInit, OnDestroy {
  sessions: ResourceCollection<Session>;
  sessionChannel: ActionCable.Channel;
  sessionChanges: Subscription;

  constructor(
    private tokenService: TokenService,
    private sessionService: Sessions,
    private cable: Cable,
    private router: Router,
    private translate: TranslateService,
    private notifier: Notifier
  ) {}

  ngOnInit() {
    this.sessions = this.sessionService.$findAll('dashboard');
    this.sessionChanges = this.sessions.$asObservable().subscribe((list) => list.$sortBy('createdAt'));
    this.notifier.requestPermission();
    this.sessionChannel = this.cable.create('ClaimsListChannel', (data: ClaimsListChannelData) => {
      if (data.started) {
        if (!this.sessions.$findById(data.id)) {
          this.sessionService
            .$find(data.id)
            .asPromise()
            .then((session: Session) => {
              this.sessions.$add(session);
              this.notify(session);
            });
        }
      }

      const claimedBySomebodyElse = data.claimed && data.claimedBy !== this.tokenService.currentUserData.id;
      if (data.finished || claimedBySomebodyElse) {
        this.sessions.$remove(data.id);
      }
    });
  }

  ngOnDestroy() {
    this.sessionChanges.unsubscribe();
    this.sessionChannel.unsubscribe();
  }

  private notify(session: Session) {
    this.translate.get('claims-list.component.notification').subscribe((message) => {
      const notification = this.notifier.notify(message);
      if (notification) {
        notification.addEventListener('click', () => {
          window.focus();
          this.sessionService.claim(session).then(() => this.router.navigate(['sessions', session.id]));
        });
      }
    });
  }

  get liveSessions(): Session[] {
    return this.sessions.$where({ liveAgent: true });
  }

  get offlineSessions(): Session[] {
    return this.sessions.$where({ liveAgent: false });
  }
}
