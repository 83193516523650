import { Account } from '@models/account';
import { User } from '@models/user';

import { ApplicationPolicy } from './application.policy';

export class AccountPolicy extends ApplicationPolicy {
  constructor(user: User, private account: Account) {
    super(user);
  }

  create(): boolean {
    return this.isStaff();
  }
}
