import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractVerifyPhotoDirective } from '@directives/abstract-verify-photo.directive';
import { Photo } from '@models/photo';
import { AttacheService } from '@services/attache';
import { isOnMobile } from '@services/browser';

@Component({
  selector: 'lv-verify-id',
  styleUrls: ['verify-id.component.sass'],
  templateUrl: 'verify-id.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyIdComponent extends AbstractVerifyPhotoDirective {
  constructor(attache: AttacheService, sanitizer: DomSanitizer) {
    super(attache, sanitizer);
  }

  get isOnDesktop(): boolean {
    return !isOnMobile();
  }

  handlePhoto(photo: Photo) {
    this.channel.perform('rebroadcast', { freshIdPhoto: photo.payload });
    this.session.idPhoto = photo;
  }
}
