<h3 translate>walk-step-id.component.title</h3>
<lv-script-toggle>
  <p translate>walk-step-id.component.script.intro</p>
</lv-script-toggle>
<p translate>walk-step-id.component.info.intro</p>
<ul>
  <li translate>walk-step-id.component.info.id-clear</li>
  <li translate>walk-step-id.component.info.id-center</li>
  <li translate>walk-step-id.component.info.id-fill</li>
  <li translate>walk-step-id.component.info.text-readable</li>
  <li translate>walk-step-id.component.info.match-face</li>
  <li translate>walk-step-id.component.info.match-name</li>
</ul>
<p translate>walk-step-id.component.info.not-satisfied</p>
<lv-script-toggle>
  <p translate>walk-step-id.component.script.issue</p>
</lv-script-toggle>
<p translate>walk-step-id.component.info.outro</p>
