export function Deserialize(fn: (value: any) => any) {
  return function (target: any, key: string): any {
    const getter = function () {
      return this[`$$${key}`];
    };

    const setter = function (newValue: any) {
      this[`$$${key}`] = fn(newValue);
    };

    if (delete target[key]) {
      Object.defineProperty(target, key, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}
