import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Anomaly } from '@models/anomaly';
import { Session } from '@models/session';
import { EventService } from '@services/event-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lv-attendance-timeline-session',
  templateUrl: 'attendance-timeline-session.component.html',
  styleUrls: ['attendance-timeline-session.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceTimelineSessionComponent implements OnInit, OnDestroy {
  @Input() session: Session;
  @Input() sessionCount: number;
  @Input() selectedAnomaly: Anomaly;

  private ngUnsubscribe = new Subject<EventService>();

  constructor(private renderer: Renderer2, private el: ElementRef, private events: EventService) {}

  @HostListener('mouseenter') mouseEnter() {
    this.events.publish('session:focus', this.session);
  }

  @HostListener('mouseleave') mouseLeave() {
    this.events.publish('session:focus');
  }

  ngOnInit() {
    // ng doesnt like css vars in hostbindings :(
    const buffer = 2;
    this.renderer.setAttribute(this.el.nativeElement, 'style', `--grow: ${buffer + this.sessionCount * buffer}`);

    this.events
      .of('session:focus')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((session) => {
        if (session && session !== this.session) {
          this.renderer.addClass(this.el.nativeElement, 'is-shrunk');
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'is-shrunk');
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
