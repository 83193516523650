import { Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Identification } from '@models/identification';
import { Session, Sessions } from '@models/session';
import { Users } from '@models/user';
import { Cable } from '@services/cable';
import * as ActionCable from '@rails/actioncable';

interface SessionChannelData {
  started?: boolean;
  finished?: boolean;
  currentStep?: number;
  biometricsUpdate?: any;
  biometrics?: any;
  nextStep?: number;
}

@Component({
  selector: 'lv-session',
  templateUrl: './session.component.html',
  styleUrls: ['session.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SessionComponent implements OnInit, OnDestroy {
  session: Session;
  passed = new EventEmitter<boolean>();
  failed = new EventEmitter<string>();
  channel: ActionCable.Channel;

  step = 5; // live agent step
  nextStep: number;

  biometricsPassed = false;

  constructor(
    private sessionService: Sessions,
    private route: ActivatedRoute,
    private router: Router,
    private cable: Cable,
    private userService: Users
  ) {}

  get identification(): Identification {
    return this.session.activeIdentification;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.session = this.sessionService.$find(params.id);
      const currentUser = this.userService.currentUser();
      Promise.all([this.session.asPromise(), currentUser.asPromise()]).then(
        ([session, user]) => {
          if (session.claimedById !== user.id) {
            this.router.navigateByUrl('/');
            return;
          }
          const cableParams = {
            channel: 'SessionChannel',
            token: session.token
          };
          this.channel = this.cable.create(cableParams, (data: SessionChannelData) => {
            if (data.started || data.finished) {
              this.session.$refresh();
            }

            if (data.currentStep) {
              this.step = data.currentStep;
            }

            if (data.nextStep) {
              this.nextStep = data.nextStep;
            }

            if (data.biometrics) {
              this.biometricsPassed = data.biometrics.verified === true || data.biometrics.trained === true;
            }
          });
        },
        (err) => {
          console.error(err);
          this.router.navigateByUrl('/');
        }
      );
    });
  }

  ngOnDestroy() {
    if (this.channel) {
      this.channel.unsubscribe();
    }
  }

  get continueKey(): string {
    switch (this.step) {
      case 3: // face photo
        return this.nextStep ? 'return-biometrics' : 'id-photo';
      case 4: // id photo
        return this.nextStep ? 'return-biometrics' : 'biometrics';
      case 5: // initial live agent screen
        return 'biometrics';
      case 6: // biometrics
        return 'ready';
      case 7: // ready
        return 'begin';
      default:
        return 'begin';
    }
  }

  next() {
    if (this.nextStep) {
      this.channel.perform('rebroadcast', { currentStep: this.nextStep });
      this.nextStep = undefined;
      return;
    }

    const shouldPass = this.step === 7;
    this.channel.perform('rebroadcast', { next: true });
    if (shouldPass) {
      setInterval(() => {
        if (this.session.canPass()) {
          this.passed.emit(true);
        }
      }, 1000);
    }
  }

  nextEnabled(): boolean {
    switch (this.step) {
      case 3: // face photo
      case 4: // id photo
        return true; // worry about these guys later
      case 5: // initial live agent screen
        return this.session.tokbox && this.session.tokbox.connected;
      case 6: // biometrics
        return this.biometricsPassed;
      case 7: // ready
        return true;
      default:
        return false;
    }
  }
}
