import { EventEmitter, Injectable } from '@angular/core';
import { FaceTrackingData } from '@models/face-tracking-data';
import { auditTime } from 'rxjs/operators';

import { CameraStream } from './camera';

@Injectable({ providedIn: 'root' })
export abstract class FaceTracker {
  stream: CameraStream;
  faceTrackingData = new EventEmitter<FaceTrackingData>();
  live = false;
  interval = 500;

  start(stream: CameraStream, callback: (data: FaceTrackingData) => void) {
    this.stream = stream;
    if (this.live) {
      this.faceTrackingData.subscribe(callback);
      this.trackFacesLive();
    } else {
      this.faceTrackingData
        .pipe(
          auditTime(this.interval) // emit values every 500ms max
        )
        .subscribe(callback);
      this.run();
    }
  }

  protected run() {
    setInterval(() => {
      this.trackFaces();
    }, this.interval);
  }

  private trackFacesLive() {
    requestAnimationFrame(() => this.trackFacesLive());
    this.trackFaces();
  }

  abstract initialize(): Promise<this>;

  protected abstract trackFaces();
}
