import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FaceTrackingData } from '@models/face-tracking-data';
import { Photo } from '@models/photo';
import { Camera, CameraStream } from '@services/camera';
import { FaceTracker } from '@services/face-tracker';
import * as _ from 'underscore';

interface Point {
  time: number;
  value: number;
}

class Rolling {
  start: number;
  data: Point[];

  constructor() {
    this.start = this.now();
    this.data = [];
  }

  now(): number {
    return performance.now();
  }

  push(value: number) {
    this.data.unshift({
      value: value,
      time: this.now()
    });
  }

  average(back: number) {
    const then = this.now() - back;
    const filtered = this.data.filter((point) => point.time > then);
    if (filtered.length < this.data.length) {
      this.data.splice(filtered.length);
    }
    if (filtered.length === 0) {
      return 0;
    }
    const total = filtered.reduce((sum, point) => sum + point.value, 0);
    return total / filtered.length;
  }
}

class PresenceData {
  lowerThreshold = 0.8;
  upperThreshold = 1.2;
  rollingAverage = 5000;

  faceCount: number;
  lastFaceCount: number;

  constructor() {
    this.faceCount = 0;
    this.lastFaceCount = 0;
  }

  updateFrom(rolling: Rolling) {
    const faces = rolling.average(this.rollingAverage);
    this.lastFaceCount = this.faceCount;
    this.faceCount = faces;
  }

  anomalyDetected(): boolean {
    return this.absent() || this.receivingHelp();
  }

  absent(): boolean {
    return this.faceCount < this.lowerThreshold;
  }

  receivingHelp(): boolean {
    return this.faceCount > this.upperThreshold;
  }
}

@Component({
  selector: 'lv-time-lapse',
  templateUrl: './time-lapse.component.html',
  styleUrls: ['./time-lapse.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class TimeLapseComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: true, read: ElementRef }) containerElement: ElementRef;

  @Input() interval: number;
  @Input() anomalyInterval: number;
  @Output() capture = new EventEmitter<Photo>();
  @HostBinding('class.is-hidden') isHidden = true;

  stream: CameraStream;
  intervalId;
  filenameIndex = 0;

  tracker: any;
  presenceData: PresenceData;
  currentFaceData: FaceTrackingData;
  rolling: Rolling;
  throttledEmit: () => void;

  get container(): HTMLElement {
    return this.containerElement.nativeElement;
  }

  get running(): boolean {
    return !!this.currentFaceData;
  }

  constructor(private zone: NgZone, private camera: Camera, private faceTracker: FaceTracker) {
    this.presenceData = new PresenceData();
  }

  ngOnInit() {
    this.rolling = new Rolling();
    // Don't emit more than once per anomalyInterval seconds
    this.throttledEmit = _.throttle(() => {
      this.emit();
    }, this.anomalyInterval * 1000);
    this.stream = this.camera.streamTo(this.container, () => this.startTimeLapse());
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    this.stream.stop();
  }

  startTimeLapse() {
    this.faceTracker.initialize().then((tracker) => {
      tracker.start(this.stream, (data) => this.trackFaceData(data));
      // wait 3 seconds to let the camera adjust focus/exposure, get face tracking online
      setTimeout(() => {
        this.presenceCheck();
        this.throttledEmit();

        setInterval(() => {
          this.presenceCheck();
        }, 1000);
      }, 3000);

      // Normally emit every interval seconds
      this.intervalId = setInterval(() => this.emit(), this.interval * 1000);
    });
  }

  emit() {
    // If we don't have face tracking data yet, try again.
    if (!this.currentFaceData) {
      this.throttledEmit();
      return;
    }

    this.camera.snapBlob(this.stream).then((blob) => {
      this.zone.run(() => {
        const photo = new Photo(blob, `time-lapse-${++this.filenameIndex}.png`, this.currentFaceData);
        this.capture.emit(photo);
      });
    });
  }

  private trackFaceData(ftd: FaceTrackingData) {
    this.currentFaceData = ftd;
    this.rolling.push(ftd.faces.length);
  }

  private presenceCheck() {
    this.presenceData.updateFrom(this.rolling);

    if (this.presenceData.anomalyDetected()) {
      this.throttledEmit();
    }
  }
}
