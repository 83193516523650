import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ResourceCollection } from '@models/resource';
import { Session } from '@models/session';

@Component({
  selector: 'lv-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['claims-list.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimsListComponent {
  @Input() sessions: ResourceCollection<Session>;

  trackBy(session: Session) {
    return session.id;
  }
}
