<nav class="site-nav">
  <ul class="site-nav__menu">
    <li>
      <a routerLink="/" translate>site-nav.component.dashboard</a>
    </li>
    <li>
      <a routerLink="/users" translate>site-nav.component.users</a>
    </li>
    <li *lvPermit="['create', 'Account']">
      <a routerLink="/accounts" translate>site-nav.component.accounts</a>
    </li>
    <li>
      <a routerLink="/profile" translate>site-nav.component.profile</a>
    </li>
    <li>
      <a lvLogout translate>app.logout</a>
    </li>
  </ul>
</nav>
