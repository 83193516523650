import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lv-copyright',
  styleUrls: ['copyright.component.sass'],
  templateUrl: './copyright.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CopyrightComponent {
  params() {
    return {
      year: new Date().getFullYear()
    };
  }
}
