import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Account } from '@models/account';
import { User, Users } from '@models/user';
import { Policy } from '@policies/policy';
import * as Policies from 'src/app/policies';

const Models = {
  Account: Account
};

export class Pundit {
  static policy(user: User, record: any): Policy {
    // Pundit.policy(user, 'Account')
    if (record in Models) {
      return new Policies[`${record}Policy`](user, Models[record]);
    }

    // Pundit.policy(user, Account)
    if (record.name in Models) {
      return new Policies[`${record.name}Policy`](user, record);
    }

    // Pundit.policy(user, new Account())
    return new Policies[`${record.constructor.name}Policy`](user, record);
  }
}

interface PunditGuardData {
  resource: string;
  permission: string;
}

@Injectable({ providedIn: 'root' })
export class PunditGuard implements CanActivate, CanActivateChild {
  constructor(private userService: Users, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.userService
      .currentUser()
      .asPromise()
      .then(
        (user) => {
          const data = <PunditGuardData>route.data;
          const policy = Pundit.policy(user, data.resource);
          const ok: boolean = policy[data.permission]();
          if (!ok) {
            this.router.navigateByUrl('/');
            return false;
          }
          return ok;
        },
        (err) => {
          console.log(err);
          return false;
        }
      );
  }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
