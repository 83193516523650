<div class="body-header">
  <h3 translate>verify-id.component.title</h3>
</div>
<div class="body-content">
  <div class="content" [innerHTML]="'verify-id.component.content' | translate"></div>
  <lv-details-panel label="app.help" [open]="true">
    <p translate>verify-id.component.id-info</p>
    <br />
    <img src="assets/IDcapture.svg" />
  </lv-details-panel>
  <div class="photo-wrapper">
    <div class="svg-wrapper" *ngIf="!photo">
      <lv-camera-feed
        [class.mirror]="isOnDesktop"
        filename="id.png"
        facing="environment"
        [keepRunning]="session.liveAgent"
        (capture)="capture($event)"
      ></lv-camera-feed>
      <img class="svg-item" src="assets/id-rect.svg" />
    </div>
    <img id="the-photo" *ngIf="photo" [src]="photoURL" />
    <button *ngIf="photo" class="button is-secondary is-fullwidth" (click)="reset()">
      <span class="icon">
        <i lvIcon="refresh"></i>
      </span>
      <span translate>verify-id.component.try-again</span>
    </button>
    <div *ngIf="saving" class="photo-uploading" translate>app.uploading</div>
  </div>
</div>
<div class="body-footer">
  <div *ngIf="showNextButton" class="field is-grouped step-navigation">
    <button class="button is-secondary is-fullwidth back-button" (click)="previous()" *ngIf="canShowBack()">
      <span class="icon">
        <i lvIcon="long-arrow-left"></i>
      </span>
      <span translate>app.back</span>
    </button>
    <button [disabled]="nextDisabled" class="button is-primary is-fullwidth next-button" (click)="done()">
      <span translate>app.next</span>
      <span class="icon">
        <i lvIcon="long-arrow-right"></i>
      </span>
    </button>
  </div>
</div>
