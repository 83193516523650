import { Injectable } from '@angular/core';
import { nativeDetectorAvailable } from '@utils/native-detector';

@Injectable({ providedIn: 'root' })
export class CameraDetectorService {
  nativeCameraAvailable = false;

  detectNativeCamera() {
    return nativeDetectorAvailable().then((available) => (this.nativeCameraAvailable = available));
  }
}
