import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lv-system-test-item',
  templateUrl: './system-test-item.component.html',
  styleUrls: ['./system-test-item.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SystemTestItemComponent implements OnInit {
  @Input() icon: string;
  @Input() key: string;
  @Input() labelParams: any;
  @Input() working: boolean;

  label: string;
  failure: SafeHtml;

  constructor(private translate: TranslateService, private sanitizer: DomSanitizer) {}

  @HostBinding('class.is-success') get isSuccess() {
    return this.working;
  }
  @HostBinding('class.is-failure') get isFailure() {
    return !this.working;
  }

  ngOnInit() {
    const failureKey = `system-test-item.component.${this.key}.failure`;
    this.translate.get(failureKey, this.labelParams).subscribe((text) => {
      this.failure = this.sanitizer.bypassSecurityTrustHtml(text);
    });

    const labelKey = `system-test-item.component.${this.key}.label`;
    this.translate.get(labelKey, this.labelParams).subscribe((text) => (this.label = text));
  }

  stateIcon(): string {
    return this.working ? 'check' : 'ban';
  }
}
