import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '@services/token-service';

@Component({
  selector: 'lv-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(tokenService: TokenService, translateService: TranslateService) {
    tokenService.init();
    const locale = new URLSearchParams(document.location.search).get('locale');
    if (locale) {
      translateService.use(locale);
    }
  }
}
