<h1>{{ user.name }}</h1>
<h5>
  <a href="mailto:{{ user.email }}">{{ user.email }}</a>
</h5>
<hr>
<table>
  <thead>
    <tr>
      <th translate>app.id</th>
      <th translate>session.website</th>
      <th translate>session.name</th>
      <th translate>session.learner</th>
      <th translate>session.services</th>
      <th translate>verification.service-provided</th>
      <th translate>verification.created-at</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let session of sessions.$toArray() | paginate:paginationConfig()">
      <td>{{ session.id }}</td>
      <td>{{ session.domain }}</td>
      <td>{{ session.name }}</td>
      <td>{{ session.learner.name }}</td>
      <td>{{ services(session) }}</td>
      <td>{{ servicesVerified(session) }}</td>
      <td class="nowrap">{{ verifiedAt(session) | date:'medium' }}</td>
    </tr>
  </tbody>
</table>

<pagination-controls (pageChange)="visit($event)" (pageBoundsCorrection)="visit($event)"></pagination-controls>
