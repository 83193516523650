<lv-session-details [session]="session" [passed]="passed" [failed]="failed"></lv-session-details>
<main *ngIf="session.$resolved">
  <div class="agent-info" *ngIf="session.liveAgent" [ngSwitch]="step">
    <lv-walk-step-photo *ngSwitchCase="3"></lv-walk-step-photo>
    <lv-walk-step-id *ngSwitchCase="4"></lv-walk-step-id>
    <lv-walk-step-intro *ngSwitchCase="5"></lv-walk-step-intro>
    <lv-walk-step-biometrics *ngSwitchCase="6"></lv-walk-step-biometrics>
    <lv-agent-verify-biometrics [session]="session" *ngSwitchCase="6"></lv-agent-verify-biometrics>
    <lv-walk-step-complete *ngSwitchCase="7" [session]="session"></lv-walk-step-complete>
    <button class="button is-success is-medium" (click)="next()" [disabled]="!nextEnabled()" translate>session.component.continue.{{ continueKey }}</button>
  </div>

  <lv-agent-verify-id *ngIf="!session.idVerified" [session]="session" [passed]="passed" [failed]="failed"></lv-agent-verify-id>
  <lv-agent-verify-attendance *ngIf="session.idVerified" [session]="session" [passed]="passed" [failed]="failed"></lv-agent-verify-attendance>
</main>
