import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { User, Users } from '@models/user';
import { TokenService, UpdatePasswordData } from '@services/token-service';

@Component({
  selector: 'lv-edit-profile',
  templateUrl: 'edit-profile.component.html',
  styleUrls: ['edit-profile.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class EditProfileComponent implements OnInit {
  user: User;
  updates: UpdatePasswordData;

  constructor(private userService: Users, private tokenService: TokenService, private router: Router) {}

  passwordsAreSame(): boolean {
    return this.updates.password === this.updates.passwordConfirmation;
  }

  ngOnInit() {
    this.user = this.userService.currentUser();
    this.reset();
  }

  reset() {
    this.updates = {
      password: '',
      passwordConfirmation: ''
    };
  }

  valid(form: NgForm): boolean {
    return form.valid && this.passwordsAreSame();
  }

  updatePassword() {
    this.tokenService.updatePassword(this.updates).subscribe(
      () => this.router.navigateByUrl('/'),
      () => this.reset()
    );
  }
}
