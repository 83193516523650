import { Injectable } from '@angular/core';
import { NativeFaceTrackingData } from '@models/face-tracking-data';

import { FaceTracker } from './face-tracker';

@Injectable({ providedIn: 'root' })
export class NativeFaceTrackerService extends FaceTracker {
  faceDetector: FaceDetector;

  initialize(): Promise<this> {
    this.faceDetector = new FaceDetector({ maxDetectedFaces: 2, fastMode: false });
    return Promise.resolve(this);
  }

  protected trackFaces() {
    this.stream.camera
      .snapData(this.stream)
      .then((imageData) => this.faceDetector.detect(imageData))
      .then((faces) => {
        this.faceTrackingData.emit(new NativeFaceTrackingData(faces));
      });
  }
}
