<h3 translate>walk-step-photo.component.title</h3>
<lv-script-toggle>
  <p translate>walk-step-photo.component.script.intro</p>
</lv-script-toggle>
<p translate>walk-step-photo.component.info.intro</p>
<ul>
  <li translate>walk-step-photo.component.info.clear-photo</li>
  <li translate>walk-step-photo.component.info.face-center</li>
  <li translate>walk-step-photo.component.info.face-fill</li>
  <li translate>walk-step-photo.component.info.only-person</li>
</ul>
<p translate>walk-step-photo.component.info.not-satisfied</p>
<lv-script-toggle>
  <p translate>walk-step-photo.component.script.issue</p>
</lv-script-toggle>
<p translate>walk-step-photo.component.info.outro</p>
