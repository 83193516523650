<section lvCloseOverlay withKeyboard="esc">
  <h4 translate>biometrics-error.component.title</h4>
  <div class="content">
    <p translate>biometrics-error.component.message</p>
    <div class="field is-grouped is-grouped-right">
      <button class="button" (click)="hardRestart()" [disabled]="!allowHardRestart" translate>biometrics-error.component.reset</button>
      <button class="button" (click)="resetBiometrics()" translate>app.try-again</button>
    </div>
  </div>
</section>
