<div class="body-header">
  <h3 translate>verify-agent.component.title</h3>
</div>
<div class="body-content">
  <div class="content">
    <p>
      <strong translate>app.instructions</strong>
    </p>
    <p translate>verify-agent.component.content</p>
    <p>
      <strong translate>verify-agent.component.click-below</strong>
    </p>
    <div class="photo-wrapper">
      <lv-camera-feed [showCapture]="false" [keepRunning]="true"></lv-camera-feed>
      <button class="button is-primary is-fullwidth" (click)="connectLiveAgent()" [disabled]="liveAgentConnected">
        <span translate>app.{{ label() }}</span>
        <span class="icon">
          <i class="connect-icon" [lvIcon]="icon()"></i>
        </span>
      </button>
    </div>
  </div>
</div>
<div class="body-footer"></div>
