import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Anomaly } from '@models/anomaly';
import { EventService } from '@services/event-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lv-attendance-timeline-anomaly',
  styleUrls: ['attendance-timeline-anomaly.component.sass'],
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class AttendanceTimelineAnomalyComponent implements OnInit, OnDestroy {
  @Input() anomaly: Anomaly;
  @Input() sessionStart: Date;
  @Input() sessionEnd: Date;

  @HostBinding('class.is-active') selected = false;

  private ngUnsubscribe = new Subject<EventService>();

  constructor(private events: EventService) {}

  @HostBinding('style.left')
  get percentage(): string {
    const startTime: number = this.sessionStart.getTime();
    const endTime: number = this.sessionEnd.getTime();
    const anomTime: number = this.anomaly.createdAt.getTime();
    const p: number = (anomTime - startTime) / (endTime - startTime);

    return p * 100 + '%';
  }

  @HostListener('click') dispatchSelect() {
    this.events.publish('anomaly:select', this.anomaly);
  }

  ngOnInit() {
    this.events
      .of('anomaly:select')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((anomaly) => {
        this.selected = anomaly === this.anomaly;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
