import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { LocalStorageProxy } from '@utils/local-storage-proxy';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

export interface SignInData {
  email: string;
  password: string;
}

export interface RegisterData {
  email: string;
  password: string;
  passwordConfirmation: string;
  name?: string;
}

export interface UpdatePasswordData {
  password: string;
  passwordConfirmation: string;
  passwordCurrent?: string;
  resetPasswordToken?: string;
}

export interface ResetPasswordData {
  email: string;
}

export interface AuthData {
  accessToken: string;
  client: string;
  expiry: string;
  tokenType: string;
  uid: string;
}

export interface UserData {
  id: number;
  provider: string;
  uid: string;
  name: string;
  nickname: string;
  image: any;
  email: string;
}

interface UserDataResponse {
  data: UserData;
}

export interface GlobalOptions {
  headers: HttpHeaders;
}

export interface TokenServiceOptions {
  localStoragePrefix: string;
  apiBase: string;

  signInPath: string;
  signInRedirect: string;

  signOutPath: string;
  validateTokenPath: string;
  signOutFailedValidate: boolean;

  registerAccountPath: string;
  registerAccountCallback?: string;

  updatePasswordPath: string;

  resetPasswordPath: string;
  resetPasswordCallback?: string;

  globalOptions: GlobalOptions;
  validMinutes: number;
}

@Injectable({ providedIn: 'root' })
export class TokenService implements CanActivate {
  get currentUserData() {
    return this._currentUserData;
  }

  get currentAuthData() {
    return this._currentAuthData;
  }

  get currentAuthHeaders(): HttpHeaders {
    if (this._currentAuthData) {
      return new HttpHeaders({
        'access-token': this._currentAuthData.accessToken,
        client: this._currentAuthData.client,
        expiry: this._currentAuthData.expiry,
        'token-type': this._currentAuthData.tokenType,
        uid: this._currentAuthData.uid
      });
    }

    return new HttpHeaders();
  }

  private validatedAt = 0;
  private _options: TokenServiceOptions;
  private _currentAuthData: AuthData;
  private _currentUserData: UserData;
  private storage: LocalStorageProxy;

  constructor(private http: HttpClient, @Optional() private router: Router) {}

  canActivate(): Promise<boolean> {
    return this.validateToken();
  }

  setRedirectTo(url: string) {
    this.storage.set('redirectTo', url);
  }

  redirectTo(): string | null {
    return this.storage.get('redirectTo');
  }

  init(options?: TokenServiceOptions) {
    if (this._options) {
      return;
    }

    const defaultOptions: TokenServiceOptions = {
      localStoragePrefix: 'TokenService',
      apiBase: environment.apiBase,

      signInPath: 'auth/sign_in',
      signInRedirect: 'auth/sign_in',

      signOutPath: 'auth/sign_out',
      validateTokenPath: 'auth/validate_token',
      signOutFailedValidate: false,

      registerAccountPath: 'auth',
      registerAccountCallback: window.location.href,

      updatePasswordPath: 'auth/password',

      resetPasswordPath: 'auth/password',
      resetPasswordCallback: window.location.href,

      validMinutes: 5,

      globalOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    };

    this._options = (<any>Object).assign(defaultOptions, options);

    this.storage = new LocalStorageProxy(this._options.localStoragePrefix);

    this.getAuthDataFromStorage();
  }

  registerAccount(registerData: RegisterData) {
    const body = JSON.stringify({
      email: registerData.email,
      name: registerData.name,
      password: registerData.password,
      password_confirmation: registerData.passwordConfirmation,
      confirm_success_url: this._options.registerAccountCallback
    });

    return this.post(this._options.registerAccountPath, body);
  }

  signIn(signInData: SignInData) {
    const body = JSON.stringify({
      email: signInData.email,
      password: signInData.password
    });

    const observ = this.post<UserDataResponse>(this._options.signInPath, body);

    observ.subscribe(
      (res) => (this._currentUserData = res.body.data),
      () => null
    );

    return observ;
  }

  signOut() {
    const observ = this.delete(this._options.signOutPath);

    this.clearAuthData();

    return observ;
  }

  valid(): boolean {
    const duration = this._options.validMinutes * 60 * 1000; // convert to milliseconds
    return Date.now() - this.validatedAt < duration;
  }

  // Validate token request
  validateToken(): Promise<boolean> {
    this.init();

    if (this.valid()) {
      return Promise.resolve(true);
    }

    const observ = this.get<UserDataResponse>(this._options.validateTokenPath);

    return observ.toPromise(Promise).then(
      (res) => {
        this.validatedAt = Date.now();
        this._currentUserData = res.body.data;
        return true;
      },
      (error) => {
        if (error.status === 401) {
          this.clearAuthData();
        }
        return false;
      }
    );
  }

  updatePassword(updatePasswordData: UpdatePasswordData) {
    let args: any;

    if (updatePasswordData.passwordCurrent == null) {
      args = {
        password: updatePasswordData.password,
        password_confirmation: updatePasswordData.passwordConfirmation
      };
    } else {
      args = {
        current_password: updatePasswordData.passwordCurrent,
        password: updatePasswordData.password,
        password_confirmation: updatePasswordData.passwordConfirmation
      };
    }

    if (updatePasswordData.resetPasswordToken) {
      args.reset_password_token = updatePasswordData.resetPasswordToken;
    }

    const body = JSON.stringify(args);
    return this.put(this._options.updatePasswordPath, body);
  }

  // Reset password request
  resetPassword(resetPasswordData: ResetPasswordData) {
    const body = JSON.stringify({
      email: resetPasswordData.email,
      redirect_url: this._options.resetPasswordCallback
    });

    return this.post(this._options.resetPasswordPath, body);
  }

  get<T>(path: string, params?: HttpParams) {
    return this.request<T>('GET', this.getApiPath() + path, null, params);
  }

  post<T>(path: string, body: any, params?: HttpParams) {
    return this.request<T>('POST', this.getApiPath() + path, body, params);
  }

  put<T>(path: string, body: any, params?: HttpParams) {
    return this.request<T>('PUT', this.getApiPath() + path, body, params);
  }

  delete<T>(path: string, params?: HttpParams) {
    return this.request<T>('DELETE', this.getApiPath() + path, null, params);
  }

  patch<T>(path: string, body: any, params?: HttpParams) {
    return this.request<T>('PATCH', this.getApiPath() + path, body, params);
  }

  head<T>(path: string, params?: HttpParams) {
    return this.request<T>('HEAD', this.getApiPath() + path, null, params);
  }

  options<T>(path: string, params?: HttpParams) {
    return this.request<T>('OPTIONS', this.getApiPath() + path, null, params);
  }

  // Construct and send Http request
  request<T>(
    method: string,
    url: string,
    body?: any,
    params?: HttpParams,
    extraHeaders?: HttpHeaders
  ): Observable<HttpResponse<T>> {
    let headers = this._options.globalOptions.headers;
    if (extraHeaders) {
      for (const key of extraHeaders.keys()) {
        headers = headers.set(key, extraHeaders.get(key));
      }
    }

    // Merge auth headers to request if set
    if (this._currentAuthData) {
      headers = headers
        .set('access-token', this._currentAuthData.accessToken)
        .set('client', this._currentAuthData.client)
        .set('expiry', this._currentAuthData.expiry)
        .set('token-type', this._currentAuthData.tokenType)
        .set('uid', this._currentAuthData.uid);
    }

    const response = this.http
      .request<T>(method, url, {
        body: body,
        headers: headers,
        params: params,
        observe: 'response'
      })
      .pipe(share());

    this.handleResponse<T>(response);

    return response;
  }

  // Check if response is complete and newer, then update storage
  private handleResponse<T>(response: Observable<HttpResponse<T>>) {
    response.subscribe(
      (res) => {
        this.getAuthHeadersFromResponse(res.headers);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.clearAuthData();
          this.router.navigateByUrl('auth/sign_in');
        } else {
          this.getAuthHeadersFromResponse(err.headers);
        }
      }
    );
  }

  // Parse Auth data from response
  private getAuthHeadersFromResponse(headers: HttpHeaders) {
    const authData: AuthData = {
      accessToken: headers.get('access-token'),
      client: headers.get('client'),
      expiry: headers.get('expiry'),
      tokenType: headers.get('token-type'),
      uid: headers.get('uid')
    };

    this.setAuthData(authData);
  }

  private getAuthDataFromStorage() {
    const data = this.storage.getJSON('authData');

    if (data) {
      if (this.checkAuthData(data)) {
        this._currentAuthData = data;
      }
    }
  }

  private clearAuthData() {
    this.storage.remove('authData');
    this.validatedAt = 0;
    this._currentAuthData = undefined;
    this._currentUserData = undefined;
  }

  private setAuthData(authData: AuthData) {
    if (this.checkAuthData(authData)) {
      this._currentAuthData = authData;
      this.storage.setJSON('authData', authData);
    }
  }

  private checkAuthData(authData: AuthData): boolean {
    if (authData.accessToken && authData.client && authData.expiry && authData.tokenType && authData.uid) {
      if (this._currentAuthData) {
        return authData.expiry >= this._currentAuthData.expiry;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private getApiPath(): string {
    return this._options.apiBase;
  }
}
