import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttacheOptions } from '@models/attache-options';

export interface AttachePayload {
  signature: string;
  path: string;
  bytes: number;
  geometry: string;
  contentType: string;
}

@Injectable({ providedIn: 'root' })
export class AttacheService {
  constructor(private http: HttpClient) {}

  upload(options: AttacheOptions, file: File): Promise<AttachePayload> {
    const params = new HttpParams()
      .set('uuid', options.uuid)
      .set('expiration', `${options.expiration}`)
      .set('hmac', options.hmac)
      .set('file', file.name);
    return this.http
      .put<AttachePayload>(options.uploadurl, file, { params: params })
      .toPromise();
  }
}
