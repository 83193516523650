import { EventEmitter } from '@angular/core';

type KeyMapper = (s: string) => string;

const deepMapKeys = require('deep-map-keys');
const camelize: KeyMapper = require('underscore.string/camelize');

interface Error {
  name: string;
  message: string;
}

export class Tokbox {
  publishing = false;
  subscribed = false;
  error: Error;

  apiKey: string;
  sessionId: string;
  token: string;

  session: any;

  paired: EventEmitter<boolean> = new EventEmitter();

  constructor(attributes) {
    Object.assign(this, deepMapKeys(attributes, camelize));
  }

  get connected(): boolean {
    return this.publishing && this.subscribed;
  }

  connect(them: HTMLElement, me: HTMLElement, options: OT.PublisherProperties) {
    if (this.connected || this.error) {
      return;
    }

    // This lets us reuse the element we're adding to, so reconnecting works properly.
    options.insertMode = 'append';

    this.session = OT.initSession(this.apiKey, this.sessionId);

    this.session.on({
      streamCreated: (event) => {
        this.session.subscribe(event.stream, them, options, (error: Error) => {
          if (this.error) {
            this.error = error;
          } else {
            this.subscribed = true;
            if (this.publishing) {
              this.paired.emit(true);
            }
          }
        });
      },
      streamDestroyed: () => {
        this.subscribed = false;
        this.paired.emit(false);
      }
    });

    this.session.connect(this.token, (connectError: Error) => {
      if (connectError) {
        this.error = connectError;
      } else {
        const publisher = OT.initPublisher(me, options, (publishError: Error) => {
          if (publishError) {
            this.error = publishError;
          } else {
            this.session.publish(publisher, (error: Error) => {
              if (error) {
                this.error = error;
              } else {
                this.publishing = true;
                if (this.subscribed) {
                  this.paired.emit(true);
                }
              }
            });
          }
        });
      }
    });
  }

  disconnect() {
    if (this.session) {
      this.session.disconnect();
    }
  }
}
