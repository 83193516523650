<div class="body-header">
  <h3 translate>verify-biometrics.component.{{ registerOrAuth }}.title</h3>
</div>
<div class="body-content">
  <div class="content">
    <p translate>verify-biometrics.component.instructions.first</p>
    <p translate>verify-biometrics.component.instructions.{{ photoRequiredOrTaken }}</p>
  </div>
  <div class="content">
    <lv-facebox-biometrics
      class="control"
      #biometrics
      [learner]="learner"
      [session]="session"
      [verify]="learner.biometricsTrained"
      (update)="biometricsUpdated($event)"
      [ngClass]="{ 'is-verified': verified }"
    ></lv-facebox-biometrics>
    <div class="field is-grouped">
      <button class="button is-secondary reset-btn" *ngIf="biometrics.ready()" (click)="reset()" [disabled]="verified">
        {{ 'app.try-again' | translate }}
      </button>
      <button
        class="button is-primary confirm-btn"
        [ngClass]="{ 'is-verified': verified }"
        (click)="verify()"
        [disabled]="verifyDisabled()"
      >
        {{ submitText | translate }}
      </button>
    </div>
  </div>
</div>
<div class="body-footer">
  <div class="field is-grouped step-navigation" *ngIf="!session.liveAgent">
    <button class="button is-secondary is-fullwidth back-button" (click)="previous()" *ngIf="canShowBack()">
      <span class="icon">
        <i lvIcon="long-arrow-left"></i>
      </span>
      <span translate>app.back</span>
    </button>
    <button class="button is-primary is-fullwidth next-button" (click)="done()" [disabled]="!verified">
      <span translate>app.next</span>
      <span class="icon">
        <i lvIcon="long-arrow-right"></i>
      </span>
    </button>
  </div>
</div>
