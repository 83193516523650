import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { Session } from '@models/session';

@Component({
  selector: 'lv-verify-agent',
  styleUrls: ['verify-agent.component.sass'],
  templateUrl: 'verify-agent.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyAgentComponent {
  @Input() session: Session;
  @Input() connect: EventEmitter<null>;

  private _connecting = false;

  get isConnecting(): boolean {
    return this._connecting && !this.session.tokbox.connected;
  }

  get liveAgentConnected(): boolean {
    return this.session.liveAgentConnected;
  }

  connectLiveAgent() {
    this._connecting = !this._connecting;
    this.connect.emit();
  }

  icon(): string {
    if (this.isConnecting) {
      return 'spinner';
    } else if (this.liveAgentConnected) {
      return 'check';
    }
    return 'video-camera';
  }

  label(): string {
    if (this.isConnecting) {
      return 'connecting';
    } else if (this.liveAgentConnected) {
      return 'connected';
    }
    return 'connect';
  }
}
