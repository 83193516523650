<div class="session-photos control">
  <h3 class="learner-status">
    <span translate>session-review-attendance.component.title</span>
    <span translate [ngClass]="{ 'is-success': attendanceVerificationStatus == 'passed', 'is-failure': attendanceVerificationStatus == 'failed' }">session-review.component.verified.{{ attendanceVerificationStatus }}</span>
  </h3>
  <table class="attendance-table control table is-bordered is-narrow" *ngIf="anythingEnabled">
    <thead>
      <tr>
        <td *ngIf="minimumTimeEnabled" translate>session-review-attendance.component.time</td>
        <td *ngIf="maximumAbsentEnabled" translate>session-review-attendance.component.absence</td>
        <td *ngIf="maximumAssistanceEnabled" translate>session-review-attendance.component.assistance</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngIf="minimumTimeEnabled" translate [translateParams]="{ actual: totalSessionDuration, expected: minimumTime }" [ngClass]="{ 'is-failure': totalSessionDuration < minimumTime }">session-review-attendance.component.time-usage</td>
        <td *ngIf="maximumAbsentEnabled" translate [translateParams]="{ actual: absenceCount, expected: maximumAbsent }" [ngClass]="{ 'is-failure': absenceCount > maximumAbsent }">session-review-attendance.component.usage</td>
        <td *ngIf="maximumAssistanceEnabled" translate [translateParams]="{ actual: assistanceCount, expected: maximumAssistance }" [ngClass]="{ 'is-failure': assistanceCount > maximumAssistance }">session-review-attendance.component.usage</td>
      </tr>
    </tbody>
  </table>
  <div class="carousel-selected" *ngIf="hasFlaggedPhotos">
    <img [src]="selectedPhoto?.photoUrl" lvImgBuffer/>
    <button selected-previous class="button is-primary is-medium" (click)="previousPhoto()" [disabled]="isFirstPhoto()">
      <span class="icon is-medium">
        <i lvIcon="caret-left"></i>
      </span>
    </button>
    <button selected-next class="button is-primary is-medium" (click)="nextPhoto()" [disabled]="isLastPhoto()">
      <span class="icon is-medium">
        <i lvIcon="caret-right"></i>
      </span>
    </button>
    <div selected-index translate [translateParams]="{ actual: photoNumber, expected: photoCount }">session-review-attendance.component.usage</div>
    <div selected-timestamp>{{ photoCreatedAt() | date:'yyyy-MM-dd' }} {{ photoCreatedAt() | date:'mediumTime' }}</div>
    <div selected-anomaly class="control" [attr.data-tip]="selectedComment" data-tip-length="large">
      <span>{{ selectedAnomaly?.kind }} (ID {{ selectedAnomaly?.id }})</span>
    </div>
  </div>
  <div class="anomaly-timeline" *ngIf="hasFlaggedPhotos">
    <button class="button is-secondary is-small" (click)="previousAnomaly()" [disabled]="isFirstAnomaly()">
      <span class="icon is-small">
        <i lvIcon="caret-left"></i>
      </span>
    </button>
    <lv-attendance-timeline [sessions]="sessions"></lv-attendance-timeline>
    <button selected-next class="button is-secondary is-small" (click)="nextAnomaly()" [disabled]="isLastAnomaly()">
      <span class="icon is-small">
        <i lvIcon="caret-right"></i>
      </span>
    </button>
  </div>

  <div class="no-photos" *ngIf="!hasFlaggedPhotos">
    <span class="icon is-medium">
      <i lvIcon="exclamation-triangle"></i>
    </span>
    <strong translate>session-review-attendance.component.no-flagged-photos</strong>
  </div>
</div>
