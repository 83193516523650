<button class="button" (click)="toggleScript()">
  <article class="script">
    <div class="script-header">
      <p translate>script-toggle.component.suggested</p>
      <button class="button is-small">
        <span class="icon is-small">
          <i lvIcon="times"></i>
        </span>
      </button>
    </div>
    <div class="script-body content">
      <ng-content></ng-content>
    </div>
  </article>
  <span translate>script-toggle.component.suggested</span>
  <span class="icon">
    <i lvIcon="commenting-o"></i>
  </span>
</button>
